export const reportPaths = {
  REPORT: "",
  REPORT_ADDITIONAL_CHARGE: "additional-charge",
  REPORT_INVENTORY: "inventory",
  REPORT_CATEGORY: "category",
  REPORT_DISCOUNT: "discount",
  REPORT_VOUCHER: "voucher",
  REPORT_SALES: "sales",
  REPORT_TIP: "tip",
  REPORT_FREE_CREDITS: "free-credits",
  REPORT_ACCESS_CONTROL: "access-control",
  REPORT_ON_GROUND: "on-ground",
  REPORT_DUPLICATE_TICKETS: "duplicate-tickets"
};
