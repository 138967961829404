import { Post } from "services";

export const onGroundReport= async (data) => {
  const res = await Post('inventory/guest-profile/on-ground-report/search', data);
  return res;
};

export const onGroundReportExport= async (data) => {
  const res = await Post('inventory/guest-profile/on-ground-report/export', data);
  return res;
};

export const multipleIn = async (data) => {
  const res = await Post('access-point/access-point-action/on-ground-report/multiple-in/search', data);
  return res;
};


export const multipleInExport = async (data) => {
  const res = await Post('access-point/access-point-action/on-ground-report/multiple-in/export', data);
  return res;
};

export const inactiveBookingReport = async (data) => {
  const res = await Post('inventory/booking/on-ground-report/summary/search', data);
  return res;
};

export const inactiveBookingReportExport = async (data) => {
  const res = await Post('inventory/booking/on-ground-report/summary/export', data);
  return res;
};

export const transactionRefundReport = async (data) => {
  const res = await Post('inventory/transaction/on-ground-report/transaction-refunds/search', data);
  return res;
};

export const transactionRefundReportExport = async (data) => {
  const res = await Post('/inventory/transaction/on-ground-report/transaction-refunds/export', data);
  return res;
};



export const remainingBalanceReport = async (data) => {
  const res = await Post('inventory/transaction/on-ground-report/remaining-balance/search', data);
  return res;
};

export const remainingBalanceReportExport = async (data) => {
  const res = await Post('inventory/transaction/on-ground-report/remaining-balance/export', data);
  return res;
};

export const topUpAndSaleReport = async (data) => {
  const res = await Post('inventory/transaction/on-ground-report/summary/search', data);
  return res;
};

export const topUpAndSaleReportExport = async (data) => {
  const res = await Post('inventory/transaction/on-ground-report/summary/export', data);
  return res;
};


export const transactionReport = async (data) => {
  const res = await Post('/inventory/transaction/search', data);
  return res;
};
