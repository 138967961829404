import { AlertMessage, Button, Field, Icon, Input, Modal, Text, Toast, Title } from "components";
import { HeaderD } from "components/headers";
import React, { useContext, useMemo, useState } from "react";
import styles from "./connect-modal.module.scss";
import { useForm, useModal } from "hooks";
import lang from "translations";
import { AlertType, Field as EField, StyleType } from "enums";
import { connectToSiigo } from "apis/siigo.api";
import { VenueContext } from "contexts";

const SiigoConnectModal = ({ data, background, onConnectOk, ...rest }) => {
  const { venue } = useContext(VenueContext);
  const { name, imageLink, type, company } = data;
  const [isInvalid, setIsInvalid] = useState(false);
  const confirmationModal = useModal();

  const initialStateForm = useMemo(
    () => ({
      user: {
        name: "user",
        label: lang.user,
        value: "",
        type: EField.INPUT,
        maxLength: 75,
      },
      accessKey: {
        name: "accessKey",
        label: lang.accessKey,
        value: "",
        type: EField.INPUT,
        maxLength: 75,
      },
    }),
    []
  );

  const { fields, modifyField, submitForm } = useForm({
    initialState: initialStateForm,
  });

  const onConnect = async (params) => {
    try {
      setIsInvalid(false);
      const data = {
        venueId: venue.venueId,
        username: params.user.value,
        accessKey: params.accessKey.value,
      };
      const res = await connectToSiigo(data);
      onConnectOk(res.integrationSiigoId);
      Toast({
        content: lang.connectedSuccessfully,
        success: true,
        icon: "check",
      }).open();
    } catch (error) {
      setIsInvalid(true);
      // Toast({
      //   content: lang.credentialsAreInvalid,
      //   error: true,
      //   icon: "exclamation-fill",
      // }).open();
    }
  };

  const close = () => {
    setIsInvalid(false);
    rest.close();
  };

  return (
    <Modal {...rest} close={close} noHeader width={545}>
      <div className="p-sm">
        <div className="flex py-lg justify-between">
          <HeaderD
            logoUrl={imageLink}
            title={name}
            label={type}
            description={company}
            background={background}
            className={styles.header}
          />
          <Icon name="remove" onClick={() => rest.close()} />
        </div>
        <Text fontWeight="font-semibold text-md">{`Connect ${name} to your PouchNATION account.`}</Text>

        {isInvalid && (
          <AlertMessage
            type={AlertType.Error}
            message={lang.credentialsAreInvalid}
            showIcon={true}
            icon="info"
            className="mb-lg"
          />
        )}

        <Field className="w-full" {...fields.user}>
          <Input {...fields.user} onChange={modifyField} error={fields.user.error || isInvalid} />
        </Field>
        <Field className="w-full" {...fields.accessKey} noLabel>
          <Text label className="break-all">
            {fields.accessKey.label}
          </Text>
          <Text size={"text-xs"}>
            {lang.goToThe}
            <span>
              <a href="https://siigonube.siigo.com/" className="text-pelorous text-xs">
                API Information
              </a>
            </span>{" "}
            {lang.goToTheSiigoApiKey}
          </Text>
          <Input
            {...fields.accessKey}
            onChange={modifyField}
            inputType="password"
            error={fields.accessKey.error || isInvalid}
          />
        </Field>
        <div className="flex justify-end gap-6 pb-3 pt-3.5">
          <Button type={StyleType.Secondary} onClick={close}>
            {lang.cancel}
          </Button>
          <Button
            disabled={!fields.user.value || !fields.user.value}
            onClick={() => confirmationModal.show()}
          >
            {lang.connect}
          </Button>
        </div>
      </div>
      <Modal {...confirmationModal} noHeader>
        <div className="flex py-lg justify-between">
          <Title>{`Save Changes?`}</Title>
          <Icon name="remove" onClick={confirmationModal.close} />
        </div>

        <Text className="mt-xs">
          {lang.youAreEnablingSiigo}
        </Text>

        <div className="flex my-md justify-end gap-2 pb-4">
          <Button type={StyleType.Secondary} onClick={confirmationModal.close}>
            {lang.cancel}
          </Button>
          <Button
            type={StyleType.Primary}
            onClick={() => {
              confirmationModal.close();
              submitForm(onConnect);
            }}
          >
            {lang.ok}
          </Button>
        </div>
      </Modal>
    </Modal>
  );
};

export default SiigoConnectModal;
