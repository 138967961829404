import { Post } from "services";

export const duplicateTicketsReport = async (body) => {
  const res = await Post(`inventory/guest-checkin/duplicate/on-ground-report/search`, body);
  return res;
};

export const duplicateTicketsReportExport = async (body) => {
  const res = await Post(`inventory/guest-checkin/duplicate/on-ground-report/export`, body);
  return res;
};
