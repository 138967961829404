import { duplicateTicketsReport, duplicateTicketsReportExport } from "apis";
import { DataTable, Icon } from "components/commons";
import { VenueContext } from "contexts";
import { useApi, useFilter, useMount } from "hooks";
import React, { useCallback, useContext, useMemo } from "react";
import { duplicateTicketReportFilterState } from "./filters";
import DuplicateTicketsReportFilter from "./duplicate-ticket-report-filter";
import { DuplicateTicketsReportColumns } from "./columns";
import lang from "translations";
import { HeaderA } from "components/headers";
import { ModuleWrapper } from "components/fragments";
import { mixpanel, TrackEvent } from "mixpanel";
import { toApiDateTimeFormat } from "services";

const DuplicateTicketsReport = () => {
  const { venue } = useContext(VenueContext);
  const venueId = venue?.venueId || "";
  const {
    request: searchDuplicateTicketsReportRequest,
    loading: loadingDuplicateTicketsReports,
    result: searchDuplicateTicketsReportResult = { data: [], metadata: { total: 0 } },
    mappedData,
  } = useApi({
    api: duplicateTicketsReport,
    isArray: true,
    mapper: {
      row: { key: "row" },
      ticketId: { key: "ticketId" },
      duplication: { key: "duplication" },
    },
  });

  const {
    request: searchDuplicateTicketsReportExportRequest,
    loading: searchDuplicateTicketsReportExportLoading,
  } = useApi({
    api: duplicateTicketsReportExport,
  });

  const { modifyFilter, modifyFilters, filterState, requestState, clearFilter } = useFilter(
    duplicateTicketReportFilterState(venue.venueId)
  );

  useMount(() => {
    fetchDuplicateTicketsReports(filterState, requestState);

    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.DuplicateTicketsReport,
    });
  });

  const fetchDuplicateTicketsReports = useCallback(
    (filterState, requestState) => {
      const params = {
        startDateTime: toApiDateTimeFormat(filterState.dateRange[0]),
        endDateTime: toApiDateTimeFormat(filterState.dateRange[1], true),
      };

      searchDuplicateTicketsReportRequest({ ...requestState, ...params });
    },
    [searchDuplicateTicketsReportRequest]
  );

  const clearFilterCb = useCallback(
    (filterState, requestState) => {
      fetchDuplicateTicketsReports(filterState, requestState);
    },
    [fetchDuplicateTicketsReports]
  );

  const applyFilterCb = useCallback(
    (searchKey) => {
      const { filterState, requestState } = modifyFilters({
        page: 1,
        searchKey,
      });
      fetchDuplicateTicketsReports(filterState, requestState);
    },
    [fetchDuplicateTicketsReports, modifyFilters]
  );

  const sortCb = useCallback(
    ({ value, key }) => {
      const { filterState, requestState } = modifyFilters({ sort: { key, value } });
      fetchDuplicateTicketsReports(filterState, requestState);
    },
    [fetchDuplicateTicketsReports, modifyFilters]
  );

  
   const exportXlsx = useCallback(() => {    
     const params = {
          venueId,
          startDateTime: toApiDateTimeFormat(filterState.dateRange[0]),
          endDateTime: toApiDateTimeFormat(filterState.dateRange[1], true),
          pageSize:filterState.pageSize,
          page:filterState.page
        };  
        searchDuplicateTicketsReportExportRequest(params);
    }, [searchDuplicateTicketsReportExportRequest,filterState, venueId]);

  const noResult = useMemo(() => {
    return (
      <div className="pt-lg">
        <p className="text-xl text-gray-600">{lang.noReportsFound}</p>
        <p className="text-md text-gray-400">{lang.weDidNotFoundReports}</p>
      </div>
    );
  }, []);

  return (
    <ModuleWrapper>
      <HeaderA
        title={lang.duplicateTicketsReport}
        button={{
          iconPrefix: <Icon className="mr-sm" name="download" paddingless fontSize={12} />,
          text: lang.exportXlsx,
          loading: searchDuplicateTicketsReportExportLoading,
          disabled: searchDuplicateTicketsReportExportLoading,
          onClick: () => {
            exportXlsx();
          },
        }}
        className="mb-md"
      />

      <DuplicateTicketsReportFilter
        filterState={filterState}
        requestState={requestState}
        modifyFilter={modifyFilter}
        clearFilter={clearFilter}
        onClear={clearFilterCb}
        onApply={applyFilterCb}
      />

      {searchDuplicateTicketsReportResult?.metadata?.total ? (
        <text>
          {searchDuplicateTicketsReportResult.metadata?.total} {lang.searchResults}
        </text>
      ) : (
        ""
      )}

      {mappedData.length > 0 || loadingDuplicateTicketsReports ? (
        <DataTable
          page={filterState.page}
          pageSize={filterState.pageSize}
          onChangePage={modifyFilters}
          fetchList={fetchDuplicateTicketsReports}
          total={searchDuplicateTicketsReportResult.metadata.total}
          loading={loadingDuplicateTicketsReports}
          columns={DuplicateTicketsReportColumns}
          data={mappedData}
          sort={filterState.sort}
          setSort={sortCb}
        />
      ) : (
        noResult
      )}
    </ModuleWrapper>
  );
};

export default DuplicateTicketsReport;
