export const DuplicateTicketsReportColumns = [
  {
    key: "row",
    text: "Sn.",
  },
  {
    key: "ticketId",
    text: "Ticket Id",
  },
  {
    key: "duplication",
    text: "Duplication",
  }
];
