import React from "react";
import { Path } from "paths";
import {
  InventoryReport,
  CategoryReport,
  DiscountReport,
  VoucherReport,
  SalesReport,
  AdditionalChargeReport,
  TipReport,
  FreeCreditsReport,
  OngroundReport,
  DuplicateTicketsReport
} from "modules";
import { RouteRender } from "components/commons";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useMount } from "hooks";
import { setPageTitle } from "services";
import AccessControlReport from "modules/report/access-control";

const ReportPage = () => {
  const flags = useFlags();
  const { inventoryReport, categoryReport, discountReport, voucherReport } = flags;

  useMount(() => {
    setPageTitle("reports");
  });

  return (
    <RouteRender
      defaultRedirect={Path.REPORT_SALES}
      renders={{
        [Path.REPORT_SALES]: {
          exact: true,
          component: SalesReport,
        },
        [Path.REPORT_INVENTORY]: {
          exact: true,
          component: InventoryReport,
          featureFlag: inventoryReport,
        },
        [Path.REPORT_CATEGORY]: {
          exact: true,
          component: CategoryReport,
          featureFlag: categoryReport,
        },
        [Path.REPORT_DISCOUNT]: {
          exact: true,
          component: DiscountReport,
          featureFlag: discountReport,
        },
        [Path.REPORT_VOUCHER]: {
          exact: true,
          component: VoucherReport,
          featureFlag: voucherReport,
        },
        [Path.REPORT_ADDITIONAL_CHARGE]: {
          exact: true,
          component: AdditionalChargeReport,
        },
        [Path.REPORT_TIP]: {
          exact: true,
          component: TipReport,
        },
        [Path.REPORT_FREE_CREDITS]: {
          exact: true,
          component: FreeCreditsReport,
        },
        [Path.REPORT_ACCESS_CONTROL]: {
          exact: true,
          component: AccessControlReport,
        },
        [Path.REPORT_ON_GROUND]: {
          exact: true,
          component: OngroundReport,
        },
        [Path.REPORT_DUPLICATE_TICKETS]: {
          exact: true,
          component: DuplicateTicketsReport,
        }
      }}
    />
  );
};

export default ReportPage;
