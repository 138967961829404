import React, { useCallback, useContext, useState } from "react";
import lang from "translations";
import {
  HeaderA,
  Icon,
  ModuleWrapper,
  DataTable,
  Filter,
  Field,
  RangePicker,
  Select,
  TimePicker,
  Text,
  ChartContainer,
  Title,
} from "components";
import { useApi, useFilter, useMount } from "hooks";
import { AppContext, VenueContext } from "contexts";
import moment from "moment";
import {
  onGroundReport,
  multipleIn,
  multipleInExport,
  onGroundReportExport,
  inactiveBookingReport,
  inactiveBookingReportExport,
  transactionRefundReport,
  transactionRefundReportExport,
  remainingBalanceReport,
  remainingBalanceReportExport,
  topUpAndSaleReport,
  topUpAndSaleReportExport,
  transactionReport,
} from "apis/onGoingReport.api";
import { toApiDateTimeFormat, formatNumberWithComma } from "services";
import {
  onGroundReportRequest,
  multipleInRequest,
  bookingRequest,
  transactionRefundsRequest,
  remainingBalanceRequest,
} from "./common.mapper";
import {
  multipleInRequestColumns,
  onGroundReportRequestColumns,
  bookingColumns,
  transactionRefundColumns,
  remainingBalanceColumns,
} from "./columns";

const reportConfig = {
  onGroundReport: {
    title: lang.onGroundReport,
    fetchApi: onGroundReport,
    exportApi: onGroundReportExport,
    mapper: onGroundReportRequest,
    columns: onGroundReportRequestColumns,
    isArray: true,
  },
  multipleIn: {
    title: lang.multipleInReport,
    fetchApi: multipleIn,
    exportApi: multipleInExport,
    mapper: multipleInRequest,
    columns: multipleInRequestColumns,
    isArray: true,
  },
  inactiveBookingReport: {
    title: lang.inactiveBookingReport,
    fetchApi: inactiveBookingReport,
    exportApi: inactiveBookingReportExport,
    mapper: bookingRequest,
    columns: bookingColumns,
    isArray: true,
  },
  transactionRefundReport: {
    title: lang.totalRefundReport,
    fetchApi: transactionRefundReport,
    exportApi: transactionRefundReportExport,
    mapper: transactionRefundsRequest,
    columns: transactionRefundColumns,
    isArray: false,
  },
  topupAndSaleSummaryReport: {
    title: lang.topUpAndSalesSummaryReport,
    fetchApi: topUpAndSaleReport,
    exportApi: topUpAndSaleReportExport,
    //mapper: topAndSaleRequest,
    columns: transactionRefundColumns,
    isArray: false,
  },
  remainingBalanceReport: {
    title: lang.remainingBalanceReport,
    fetchApi: remainingBalanceReport,
    exportApi: remainingBalanceReportExport,
    mapper: remainingBalanceRequest,
    columns: remainingBalanceColumns,
    isArray: false,
  },
};
const OngroundReport = () => {
  const { venue } = useContext(VenueContext);
  const { setGlobalLoading } = useContext(AppContext);
  const venueId = venue?.venueId || "";
  const defaultMode = venue?.defaultMode || "";
  const [columns, setColumns] = useState(reportConfig["onGroundReport"].columns); // Track columns in state
  const [title, setTitle] = useState(reportConfig["onGroundReport"].title); // Track columns in state
  const [selectedReport, setSelectedReport] = useState(null); // Track columns in state

  const {modifyFilter, modifyFilters, clearFilter, filterState, requestState } = useFilter({
    venueId,
    reportType: "onGroundReport",
    dateRange: [moment().startOf("day"), moment().endOf("day")],
    page: 1,
    pageSize: 20,
  });

  const {
    request: fetchReportRequest,
    result: searchResult = { data: [], metadata: { total: 0, summaryTotal : {} } },
    loading,
    mappedData,
  } = useApi({
    api: reportConfig[filterState.reportType]?.fetchApi,
    mapper: reportConfig[filterState.reportType]?.mapper,
    isArray: reportConfig[filterState.reportType]?.isArray,
    handleOwnError: true,
  });

  const {
    request: transactionReportRequest,
    result: transactionStatesResult = { metadata: { totalTag: 0, totalTagCashRemaining: 0 } },
    loading: transactionReportLoading,
  } = useApi({
    api: transactionReport,
    isArray: false,
  });

  const { request: exportReportRequest, loading: exportLoading } = useApi({
    api: reportConfig[filterState.reportType]?.exportApi,
  });

  const fetchReport = useCallback(
    async (state) => {
      
      setGlobalLoading(true);
      try {
        const { dateRange, locationState, reportType, ...restState } = state;

        const params = {
          ...restState,
          venueId,
          startDateTime: toApiDateTimeFormat(filterState.dateRange[0]),
          endDateTime: toApiDateTimeFormat(filterState.dateRange[1], true)
        };

        await fetchReportRequest(params);
        
        // Update columns based on the current report type
        const updatedColumns = reportConfig[state.reportType]?.columns;
        const reportTitle = reportConfig[state.reportType]?.title;
        setColumns(updatedColumns);
        setTitle(reportTitle);
        setSelectedReport(state.reportType);

        if (state.reportType === "topupAndSaleSummaryReport") {
          await transactionReportRequest(params);
        }

        
      } catch (error) {
        console.error("Error fetching report data:", error);
      } finally {
        setGlobalLoading(false);
      }
    },
    [fetchReportRequest, setGlobalLoading, venueId, filterState, transactionReportRequest]
  );

  const exportReport = useCallback(() => {
    const params = {
      venueId,
      startDateTime: toApiDateTimeFormat(filterState.dateRange[0]),
      endDateTime: toApiDateTimeFormat(filterState.dateRange[1], true),
    };
    exportReportRequest(params);
  }, [exportReportRequest, filterState.dateRange, venueId]);

  const applyFilter = useCallback(() => {
    fetchReport(requestState); // Fetch data and update columns
  }, [fetchReport, requestState]);

  // const clearFilterAndFetch = useCallback(() => {
  //   clearFilter();
  //   const { requestState } = clearFilter();
  //   setSelectedReport(requestState.reportType);    
  //   modifyFilter("reportType", { value: requestState.reportType });
  //   debugger;
  //   fetchReport(requestState);
   
  // }, [clearFilter, fetchReport, modifyFilter]);

  const clearFilterAndFetch = useCallback(async () => {
    const { requestState: clearedRequestState } = clearFilter();
  
    // Update state synchronously for proper synchronization with API calls
    setSelectedReport(clearedRequestState.reportType);
    modifyFilter("reportType", { value: clearedRequestState.reportType });
  debugger
    // Ensure the fetchReport is called after state updates
    await fetchReport(clearedRequestState);
  }, [clearFilter, fetchReport, modifyFilter]);

  const handleSelectChange = (name, obj) => {
    modifyFilter(name, { value: obj.value });
  };

  const handleDateRangeChange = (name, value) => {
    modifyFilter(name, { value });
  };

  const renderTopupAndSaleData = ({ data }) => {
    // Group the data by transaction type (SALE and TOPUP)
    const groupedData = Array.isArray(data)
      ? data.reduce((acc, transaction) => {
          if (transaction.transactionType) {
            const formattedItems = transaction.items.map((item) => ({
              ...item,
              transactionType: transaction.transactionType,
            }));
            const footerData = {
              guestProfileName: "Total for " + transaction.transactionType,
              totalAmount: transaction.totalAmount,
              maxAmount: transaction.maxAmount,
              minAmount: transaction.minAmount,
              avgAmount: transaction.avgAmount, // Optional: Format the avgAmount
            };

            if (!acc[transaction.transactionType]) {
              acc[transaction.transactionType] = {
                formattedData: [],
                footerData: {},
              };
            }

            // Push formatted items into formattedData for the corresponding transaction type
            acc[transaction.transactionType].formattedData.push(...formattedItems);

            // Set footer data for the corresponding transaction type
            acc[transaction.transactionType].footerData = footerData;
          }
          return acc;
        }, {})
      : {};

    // Generate a DataTable for each transaction type group
    return (
      <div>
        {!transactionReportLoading && (
          <div className="flex gap-4">
            <ChartContainer
              title={lang.totalDistinctWalletsUsedForTransactions}
              {...transactionStatesResult.metadata?.totalTag}
              h="auto"
              className="mt-2"
            >
              <Title xl className="mt-sm" fontWeight="font-normal">
                {formatNumberWithComma(transactionStatesResult.metadata?.totalTag || 0)}
              </Title>
            </ChartContainer>
            <ChartContainer
              title={lang.countOfWalletWithCashRemaining}
              {...transactionStatesResult.metadata?.totalTagCashRemaining}
              h="auto"
              className="mt-2"
            >
              <Title xl className="mt-sm" fontWeight="font-normal">
                {formatNumberWithComma(
                  transactionStatesResult.metadata?.totalTagCashRemaining || 0
                )}
              </Title>
            </ChartContainer>
          </div>
        )}

        {Object.keys(groupedData).map((transactionType) => {
          const transactionData = groupedData[transactionType].formattedData;
          const footerData = groupedData[transactionType].footerData;
          return (
            <div key={transactionType}>
              <h2>{`${transactionType} Report`}</h2>
              <DataTable
                data={transactionData}
                columns={columns} // Use the defined columns
                page={filterState.page}
                pageSize={filterState.pageSize}
                onChangePage={modifyFilters}
                total={searchResult.metadata?.total || 0}
                loading={loading}
                fetchList={fetchReport}
                footerData={footerData}
              />
            </div>
          );
        })}
      </div>
    );
  };

  useMount(() => {
    fetchReport(filterState);
  });

  return (
    <ModuleWrapper>
      <HeaderA
        title={title}
        button={{
          iconPrefix: <Icon className="mr-sm" name="download" />,
          text: lang.exportXlsx,
          loading: exportLoading,
          onClick: exportReport,
        }}
      />

      {defaultMode === "VENUE" && (
        <div className="px-6 py-4 mt-3 mb-3 rounded-lg bg-red-100 text-red text-md">
          <div className="flex gap-2">
            <span className="font-bold">{lang.yourMightGetNegative}</span>
          </div>
          <div className="whitespace-pre-wrap"></div>
        </div>
      )}

      <Filter
        className="my-lg"
        onClear={clearFilterAndFetch}
        onApply={applyFilter}
        filterState={filterState}
        searchBox={false}
        actionsSpan={3}
      >
        <Field className="col-span-3" filterLabel={lang.dateRange}>
          <RangePicker
            value={filterState.dateRange}
            name="dateRange"
            onChange={handleDateRangeChange}
          />
        </Field>

        <Field className="col-span-3" filterLabel={lang.timeRange}>
          <div className="flex items-center">
            <TimePicker
              value={filterState.dateRange[0]}
              onChange={(val) =>
                handleDateRangeChange("dateRange", [val, filterState.dateRange[1]])
              }
            />
            <Text className="mx-sm" color="text-black-light">
              to
            </Text>
            <TimePicker
              value={filterState.dateRange[1]}
              onChange={(val) =>
                handleDateRangeChange("dateRange", [filterState.dateRange[0], val])
              }
            />
          </div>
        </Field>

        <Field className="col-span-3" filterLabel={lang.report}>
          <Select
            name="reportType"
            options={[
              { value: "onGroundReport", text: lang.onGroundReport },
              { value: "multipleIn", text: lang.multipleInReport },
              { value: "inactiveBookingReport", text: lang.inactiveBookingReport },
              { value: "transactionRefundReport", text: lang.totalRefundReport },
              { value: "topupAndSaleSummaryReport", text: lang.topUpAndSalesSummaryReport },
              { value: "remainingBalanceReport", text: lang.remainingBalanceReport },
            ]}
            value={filterState.reportType}
            onChange={handleSelectChange}
            sortOptions={false}
          />
        </Field>
      </Filter>

      {loading ? (
        <Icon loading />
      ) : selectedReport === "topupAndSaleSummaryReport" ? (
        <div className="custom-report-container">
          {renderTopupAndSaleData({ data: searchResult.data })}
        </div>
      ) : (
        <div>
          {searchResult?.metadata?.total ? (
            <text>
              {searchResult.metadata?.total} {lang.searchResults}
            </text>
          ) : (
            ""
          )}

          <DataTable
            data={
              Array.isArray(mappedData.data || mappedData)
                ? mappedData.data || mappedData
                : [mappedData.data || mappedData]
            }
            columns={columns} // Use columns from state
            page={filterState.page}
            pageSize={filterState.pageSize}
            onChangePage={modifyFilters}
            total={searchResult.metadata?.total || 0}
            loading={loading}
            fetchList={fetchReport}
            footerData={mappedData.footerData || searchResult.metadata?.summaryTotal}
          />
        </div>
      )}
    </ModuleWrapper>
  );
};

export default OngroundReport;
