import React, { useCallback, useContext, useMemo, useState } from "react";
import { searchGuest, exportGuestCheckinReport } from "apis";
import { Pill, Text, DataTable, Tooltip, ButtonLink } from "components/commons";
import { useApi, useMount, useFilter, useRouter } from "hooks";
import columns from "./columns";
import { GuestStatus, PillType } from "enums";
import { mapObject, pluralize, prettifyGuestStatus } from "services";
import { guestListFilterState } from "./filters";
import { VenueContext } from "contexts";
import { guestListResponse, guestListFilterRequest } from "mappers";
import GuestFilter from "./guest-filter";
import classnames from "classnames";
import lang from "translations";
import { HeaderA } from "components/headers";
import { ModuleWrapper } from "components/fragments";
import { mixpanel, TrackEvent } from "mixpanel";
import { Path } from "paths";
import { useFlags } from "launchdarkly-react-client-sdk";
import useFilterStore, { filterName } from "hooks/filterStore";
import { isEmpty } from "lodash";

const GuestList = () => {
  const { venue } = useContext(VenueContext);
  const { history } = useRouter();
  const { guestListShowLatestPairedTag, guestListShowGuestId } = useFlags();
  const { setState: setFilterState, getState } = useFilterStore();

  const {
    error,
    request: searchGuestRequest,
    loading: loadingGuest,
    result: searchGuestResult = { data: [], metadata: { total: 0 } },
    mappedData,
  } = useApi({
    api: searchGuest,
    isArray: true,
    mapper: guestListResponse,
    handleOwnError: true,
  });

  const { request: searchGuestCheckinExportRequest, loading: searchGuestCheckinExportLoading } =
    useApi({
      api: exportGuestCheckinReport,
    });

  const { modifyFilter, modifyFilters, clearFilter, filterState, requestState, isFilterDirty } =
    useFilter(
      filterName.guest === getState().name && !isEmpty(getState().filter)
        ? getState().filter
        : guestListFilterState(venue.venueId),
      guestListFilterState(venue.venueId)
    );

  const [hasAppliedFilter, setHasAppliedFilter] = useState(isFilterDirty);

  useMount(() => {
    if (filterName.guest !== getState().name)
      setFilterState({
        name: filterName.guest,
        filter: {},
      });
    fetchGuests(requestState);
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.guestList,
    });
  });

  const fetchGuests = useCallback(
    async (requestState) => {
      await searchGuestRequest(mapObject(requestState, guestListFilterRequest));
    },
    [searchGuestRequest]
  );

  const fetchUpdateStore = useCallback(
    async (requestState, filterState) => {
      setFilterState({
        name: filterName.guest,
        filter: filterState,
      });
      fetchGuests(requestState);
    },
    [fetchGuests, setFilterState]
  );

  const renderGuestStatus = useCallback((status) => {
    return (
      <Tooltip title={prettifyGuestStatus(status)}>
        <div
          className={classnames("h-3 w-3 rounded-lg cursor-pointer", {
            "bg-white-darkest": status === GuestStatus.CHECKED_OUT,
            "bg-green-darker": status === GuestStatus.CHECKED_IN,
          })}
        ></div>
      </Tooltip>
    );
  }, []);

  const renderGuest = useCallback(
    (name, tagUids, guestId) => {
      let guestTagDisplay = "";

      if (tagUids.length > 1) {
        const lastUid = tagUids[tagUids.length - 1];
        guestTagDisplay = guestListShowLatestPairedTag ? (
          <div className="flex items-center">
            <Text className="mr-sm group-hover:text-black-light" tagUid>
              {lastUid.tagUid}
            </Text>
            <Pill type={PillType.Blueish} size="text-xs">
              {`${lastUid.useCount} use`}
            </Pill>
          </div>
        ) : (
          <Pill type={PillType.Blueish} size="text-xs">
            {tagUids.length} tags
          </Pill>
        );
      } else if (tagUids.length === 1) {
        guestTagDisplay = (
          <div className="flex items-center">
            <Text className="mr-sm group-hover:text-black-light" tagUid>
              {tagUids[0].tagUid}
            </Text>
            <Pill type={PillType.Blueish} size="text-xs">
              {tagUids[0].useCount} use
            </Pill>
          </div>
        );
      }

      return (
        <ButtonLink
          className="grid grid-cols-1 cursor-pointer group"
          path={Path.GUEST_DETAILS_ID(guestId)}
        >
          {name ? (
            <Text color="text-left text-pelorous group-hover:text-pelorous-dark">{name}</Text>
          ) : guestListShowGuestId ? (
            <Text color="text-left text-pelorous group-hover:text-pelorous-dark">
              {[lang.guest, " ", guestId]}
            </Text>
          ) : null}
          <div>{guestTagDisplay}</div>
        </ButtonLink>
      );
    },
    [guestListShowGuestId, guestListShowLatestPairedTag]
  );

  const renderDateTime = useCallback((checkInDate, checkInTime) => {
    return (
      <div>
        <Text>{checkInDate}</Text>
        <Text color="text-gray">{checkInTime}</Text>
      </div>
    );
  }, []);

  const renderCurrentGuestProfiles = useCallback(
    (currentGuestProfiles, currentGuestProfileIds) => {
      return currentGuestProfiles.map((cgp, index) => {
        return (
          <div
            className="cursor-pointer"
            onClick={() => history.push(Path.GUEST_PROFILE_ID(currentGuestProfileIds[index]))}
          >
            <Text className="hover:text-gray">{cgp}</Text>
          </div>
        );
      });
    },
    [history]
  );

  const prepareGuestList = useCallback(() => {
    return mappedData.map((guest) => {
      const {
        guestId,
        status,
        name,
        tagUids,
        transactionCount,
        checkInDate,
        checkInTime,
        checkOutDate,
        checkOutTime,
        currentGuestProfiles,
        currentGuestProfileIds,
        totalBalance,
      } = guest;

      if (tagUids.length > 1) {
        // const latestPairedTag = tagUids[tagUids.length - 1].useCount;
        const latestPairedTag = tagUids[tagUids.length - 1];
        console.log(latestPairedTag);
      }

      return {
        status: renderGuestStatus(status),
        name: renderGuest(name, tagUids, guestId),
        currentGuestProfiles: renderCurrentGuestProfiles(
          currentGuestProfiles,
          currentGuestProfileIds
        ),
        checkInDateTime: renderDateTime(checkInDate, checkInTime),
        checkOutDateTime: renderDateTime(checkOutDate, checkOutTime),
        transactionCount,
        totalBalance,
      };
    });
  }, [mappedData, renderGuestStatus, renderGuest, renderDateTime, renderCurrentGuestProfiles]);

  const guests = useMemo(() => {
    return prepareGuestList();
  }, [prepareGuestList]);

  const applyFilterCb = useCallback(
    async (searchKey) => {
      const { requestState, filterState } = modifyFilters({ page: 1, searchKey });
      await fetchUpdateStore(requestState, filterState);
      setHasAppliedFilter(true);
    },
    [fetchUpdateStore, modifyFilters]
  );

  const clearFilterCb = useCallback(() => {
    const { requestState, filterState } = clearFilter();
    fetchUpdateStore(requestState, filterState);
    setHasAppliedFilter(false);
  }, [clearFilter, fetchUpdateStore]);

  const guestResultContent = useMemo(() => {
    if (hasAppliedFilter) {
      return `${searchGuestResult.metadata.total} ${pluralize(
        searchGuestResult.metadata.total,
        lang.searchResult,
        lang.searchResults
      )}`;
    }
    return null;
  }, [searchGuestResult.metadata.total, hasAppliedFilter]);

  const exportReport = useCallback(() => {
    searchGuestCheckinExportRequest(mapObject(requestState, guestListFilterRequest));
  }, [searchGuestCheckinExportRequest, requestState]);

  return (
    <ModuleWrapper>
      <HeaderA
        title={lang.guests}
        description={lang.trackEveryGuestCheckedIn}
        button={{
          loading: searchGuestCheckinExportLoading,
          disabled:
            searchGuestCheckinExportLoading ||
            !mappedData.length ||
            searchGuestResult?.metadata?.total === 0,
          text: lang.exportGuestReport,
          onClick: () => {
            exportReport();
          },
        }}
        className="mb-md"
      />

      <GuestFilter
        filterState={filterState}
        requestState={requestState}
        modifyFilter={modifyFilter}
        applyFilter={applyFilterCb}
        clearFilter={clearFilterCb}
      />
      <text>{searchGuestResult.metadata.total} {lang.searchResults}</text>
      <DataTable
        resultContent={guestResultContent}
        page={filterState.page}
        pageSize={filterState.pageSize}
        onChangePage={modifyFilters}
        fetchList={fetchUpdateStore}
        total={searchGuestResult.metadata.total}
        loading={loadingGuest}
        columns={columns}
        data={guests}
        error={error}
        minWidth="800px"
        hasAppliedFilter={isFilterDirty && hasAppliedFilter}
      />
    </ModuleWrapper>
  );
};

export default GuestList;
