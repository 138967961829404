import React from "react";
import { Path } from "paths";
import { useMount } from "hooks";
import { setPageTitle } from "services";
import { DeviceList, EditDevice, EditPrinter, PrinterList, CreatePrinter, DeviceSyncReport } from "modules";
import { RouteRender } from "components/commons";

const DevicePage = () => {
  useMount(() => {
    setPageTitle("devices");
  });
  return (
    <RouteRender
      defaultRedirect={Path.DEVICES}
      renders={{
        [Path.PRINTER]: {
          exact: true,
          component: PrinterList,
        },
        [Path.PRINTER_CREATE]: {
          exact: true,
          component: CreatePrinter,
        },
        [Path.PRINTER_EDIT]: {
          exact: true,
          component: EditPrinter,
        },
        [Path.DEVICE_EDIT]: {
          exact: true,
          component: EditDevice,
        },
        [Path.DEVICES]: {
          exact: true,
          component: DeviceList,
        },
        [Path.DEVICE_NOTES_GUIDE]: {
          exact: true,
          component: DeviceList,
        },
        [Path.DEVICE_SYNC_STATUS]: {
          exact: true,
          component: DeviceSyncReport,
        },
      }}
    />
  );
};

export default DevicePage;
