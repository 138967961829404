import React, { useCallback, useContext, useMemo } from "react";
import lang from "translations";
import {
  HeaderA,
  ModuleWrapper,
  DataTable,
  Filter,
  Field,
  RangePicker,
  TimePicker,
  Text,
  ButtonLink
} from "components";
import { Path } from "paths";
import { useApi, useFilter, useMount } from "hooks";
import { mixpanel } from "mixpanel";
import { AppContext, VenueContext } from "contexts";
import moment from "moment";
import { searchDeviceSyncReport } from "apis/device-sync.api";
import { toApiDateTimeFormat } from "services";
import { deviceSyncStatusRequest } from "./device-sync-status.mapper";
import { deviceSyncRequestColumns } from "./columns";

const DeviceSyncReport = () => {
  const { venue } = useContext(VenueContext);
  const { setGlobalLoading } = useContext(AppContext);
  const venueId = venue?.venueId || "";

  const { modifyFilter, modifyFilters, clearFilter, filterState, requestState } = useFilter({
    venueId,
    dateRange: [moment().startOf("day"), moment().endOf("day")],
    page: 1,
    pageSize: 20,
  });

  const {
    request: fetchReportRequest,
    result: searchResult = { data: [], metadata: { total: 0 } },
    loading,
    mappedData,
  } = useApi({
    api: searchDeviceSyncReport,
    mapper: deviceSyncStatusRequest,
    isArray: true,
    params: {
      ...filterState,
    },
  });

  const fetchReport = useCallback(
    async (state) => {
      setGlobalLoading(true);
      try {
        const { dateRange, ...restState } = state;

        const params = {
          ...restState,
          venueId,
          startDateTime: toApiDateTimeFormat(filterState.dateRange[0]),
          endDateTime: toApiDateTimeFormat(filterState.dateRange[1], true),
        };

        await fetchReportRequest(params);
      } catch (error) {
        console.error("Error fetching report data:", error);
      } finally {
        setGlobalLoading(false);
      }
    },
    [fetchReportRequest, setGlobalLoading, venueId, filterState]
  );

  const applyFilter = useCallback(() => {
    fetchReport(requestState); // Fetch data and update columns
  }, [fetchReport, requestState]);

  const clearFilterAndFetch = useCallback(() => {
    clearFilter();
    fetchReport(filterState);
  }, [clearFilter, fetchReport, filterState]);

  const handleDateRangeChange = (name, value) => {
    modifyFilter(name, { value });
  };

  
  useMount(() => {
    fetchReport(requestState);
  });


  const renderLastSync = useCallback(
      (lastSync) => (
        <div>
          <Text>{lastSync?.date}</Text>
          {lastSync?.time && <Text color="text-gray">{lastSync.time}</Text>}
        </div>
      ),
      []
    );
  

    const prepareDeviceList = useCallback(() => {
      return mappedData.map((device) => {
        const { deviceId, deviceName, version, deviceImei, totalCheckIn, totalTopUp, totalSales, transactionLastSynced } = device;
        return {
          deviceId,
          deviceName: (
            <ButtonLink
              className="text-left"
              onClick={() => {
                mixpanel.track(TrackEvent.ClickedButton, {
                  Button: lang.viewDevice,
                  Page: lang.deviceList,
                });
              }}
              path={Path.DEVICE_ID(deviceId)}
            >
              {deviceName}
            </ButtonLink>
          ),
          deviceImei: <Text tagUid>{deviceImei}</Text>,
          totalCheckIn,
          totalTopUp,
          version,
          totalSales,
          transactionLastSynced: renderLastSync(transactionLastSynced),
        };
      });
    }, [mappedData, renderLastSync]);
  
    const devices = useMemo(() => {
      return prepareDeviceList();
    }, [prepareDeviceList]);

  return (
    <ModuleWrapper>
      <HeaderA title={lang.deviceSyncStatus} />

      <Filter
        className="my-lg"
        onClear={clearFilterAndFetch}
        onApply={applyFilter}
        filterState={filterState}
        searchBox={false}
        actionsSpan={6}
      >
        <Field className="col-span-3" filterLabel={lang.dateRange}>
          <RangePicker
            value={filterState.dateRange}
            name="dateRange"
            onChange={handleDateRangeChange}
          />
        </Field>

        <Field className="col-span-3" filterLabel={lang.timeRange}>
          <div className="flex items-center">
            <TimePicker
              value={filterState.dateRange[0]}
              onChange={(val) =>
                handleDateRangeChange("dateRange", [val, filterState.dateRange[1]])
              }
            />
            <Text className="mx-sm" color="text-black-light">
              to
            </Text>
            <TimePicker
              value={filterState.dateRange[1]}
              onChange={(val) =>
                handleDateRangeChange("dateRange", [filterState.dateRange[0], val])
              }
            />
          </div>
        </Field>
      </Filter>

      <DataTable
        data={devices}
        columns={deviceSyncRequestColumns()}
        page={filterState.page}
        pageSize={filterState.pageSize}
        onChangePage={modifyFilters}
        total={searchResult.metadata?.total || 0}
        loading={loading}
        fetchList={fetchReport}
        footerData={mappedData.footerData}
      />
    </ModuleWrapper>
  );
};

export default DeviceSyncReport;
