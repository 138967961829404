import {
  Field,
  RangePicker,
  ReportFilter,
  Text,
  TimePicker,
} from "components/commons";

import React, { useCallback } from "react";
import lang from "translations";

const DuplicateTicketsReportFilter = ({
  filterState,
  requestState,
  modifyFilter,
  clearFilter,
  onClear,
  onApply,
}) => {


  const applyFilterCb = useCallback(
    async (searchKey) => {
      await onApply(searchKey);
    },
    [onApply]
  );

  const clearFilterCb = useCallback(() => {
    const { filterState, requestState } = clearFilter();
    onClear(filterState, requestState);
  }, [clearFilter, onClear]);

  const changeDateRangeCb = useCallback(
    (name, value) => {
       modifyFilter(name, { value });
    },
    [modifyFilter]
  );


  return (
    <div>
      <ReportFilter
        onClear={clearFilterCb}
        onApply={applyFilterCb}
        dateRange={filterState.dateRange}
        className="my-lg"
      >
        <Field filterLabel={lang.dateRange} className="col-span-4">
          <RangePicker
            name="dateRange"
            onChange={changeDateRangeCb}
            value={filterState.dateRange}
          />
        </Field>
        <Field filterLabel={lang.timeRange} className="col-span-4">
          <div className="flex items-center">
            <TimePicker
              value={filterState.dateRange[0]}
              onChange={(startTime) => {
                modifyFilter("dateRange", {
                  value: [
                    filterState.dateRange[0].set({
                      hour: startTime.get("hour"),
                      minute: startTime.get("minute"),
                    }),
                    filterState.dateRange[1],
                  ],
                });
              }}
            />
            <Text className="mx-sm" color="text-black-light">
              to
            </Text>
            <TimePicker
              value={filterState.dateRange[1]}
              onChange={(endTime) => {
                modifyFilter("dateRange", {
                  value: [
                    filterState.dateRange[0],
                    filterState.dateRange[1].set({
                      hour: endTime.get("hour"),
                      minute: endTime.get("minute"),
                    }),
                  ],
                });
              }}
            />
          </div>
        </Field>
      </ReportFilter>
    </div>

    
  );
};

export default DuplicateTicketsReportFilter;
