import { Delete, Get, Post, Put } from "services";
import { ApiPath } from "paths";

// Conditionally use the mock or real API call
export const getPaymentGatewaysByVenueId = async (body) => {
  const res = await Get(`${ApiPath.GET_PAYMENT_GATEWAYS_BY_VENUE_ID(body.venueId)}`); // Real API
  return res;
};

export const createPaymentGateway = async (body) => {
  const res = await Post(`${ApiPath.CREATE_PAYMENT_GATEWAY}`, body); // Real API
  return res.data;
};

export const deletePaymentGateway = async ({ id }) => {
  const res = await Delete(`${ApiPath.DELETE_PAYMENT_GATEWAY(id)}`);
  return res.data;
};

export const searchPaymentGatewayByName = async (body) => {
  const res = await Get(`${ApiPath.GET_PAYMENT_GATEWAYS_BY_NAME}`, body); // Real API
  return res;
};

export const getPaymentGatewaySetting = async ({ id }) => {
  const res = await Get(`${ApiPath.GET_PAYMENT_GATEWAY_BY_ID(id)}`);
  return res;
};

export const editPaymentGatewaySetting = async (body) => {
  const res = await Post(`${ApiPath.EDIT_PAYMENT_GATEWAY}`, body);
  return res;
};

export const setPaymentGatewaySettingDefault = async (body) => {
  const res = await Put(`${ApiPath.SET_PAYMENT_GATEWAY_DEFAULT_STATUS(body.id)}`, body);
  return res;
};


export const saveTopupSetting = async (body) => {
  const res = await Post(`${ApiPath.TOP_UP_SETTING(body.paymentGatewayId)}`, body);
  return res;
};


