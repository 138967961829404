import { getLocations, searchStaff } from "apis";
import { Pill, Text, Image, Container, ButtonLink } from "components/commons";
import DataTable from "components/commons/data-table/data-table";
import { HeaderB } from "components/headers";
import { VenueContext } from "contexts";
import { PillType } from "enums";
import {
  useApi,
  useFilter,
  useModal,
  useMount,
  useSelectItems,
  // useRouter
} from "hooks";
import { staffListResponse, staffListFilterRequest } from "mappers";
import React, { useCallback, useContext, useMemo } from "react";
import { mapObject, mapObjects } from "services";
import columns from "./columns";
import lang from "translations";
import { Path } from "paths";
import { SearchingOctopus } from "images";
import { ModuleWrapper } from "components/fragments";
import { mixpanel, TrackEvent } from "mixpanel";
import useFilterStore, { filterName } from "hooks/filterStore";
import { isEmpty } from "lodash";
import useSelectID from "hooks/useSelectID";
import DeleteModal from "./delete-modal";
import StaffFilter from "./staff-filter";

const StaffList = () => {
  const { venue } = useContext(VenueContext);
  const { setState: setFilterState, getState } = useFilterStore();

  const {
    error,
    request: searchStaffRequest,
    loading: loadingStaff,
    result: searchStaffResult = { data: [], metadata: { total: 0 } },
  } = useApi({
    api: searchStaff,
    handleOwnError: true,
    isArray: true,
  });
  const {
    request: getLocationsRequest,
    loading: loadingLocations,
    result: getLocationsResult = { data: [], metadata: { total: 0 } },
  } = useApi({
    api: getLocations,
    params: { venueId: venue.venueId },
    handleOwnError: true,
  });

  const defaultFilter = useMemo(
    () => ({
      venueId: venue.venueId,
      page: 1,
      pageSize: 20,
      searchKey: "",
      locations: [],
      roles: [],
      invites: [],
    }),
    [venue.venueId]
  );

  const { modifyFilter, modifyFilters, clearFilter, filterState, requestState } = useFilter(
    filterName.staff === getState().name && !isEmpty(getState().filter)
      ? getState().filter
      : defaultFilter,
    defaultFilter
  );

  useMount(() => {
    if (filterName.staff !== getState().name)
      setFilterState({
        name: filterName.staff,
        filter: {},
      });
    fetchLocations();
    fetchStaffs(requestState);

    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.staffList,
    });
  });

  const fetchLocations = useCallback(async () => {
    await getLocationsRequest();
  }, [getLocationsRequest]);

  const fetchStaffs = useCallback(
    (requestState) => {
      searchStaffRequest(mapObject(requestState, staffListFilterRequest));
    },
    [searchStaffRequest]
  );

  const fetchUpdateStore = useCallback(
    async (requestState, filterState) => {
      setFilterState({
        name: filterName.staff,
        filter: filterState,
      });
      fetchStaffs(requestState);
    },
    [fetchStaffs, setFilterState]
  );

  const renderStaff = useCallback((name, status, id) => {
    return (
      <>
        <div
        // className="cursor-pointer"
        // onClick={() => {
        //   history.push(Path.STAFF_ID(id));
        // }}
        >
          <ButtonLink className="" path={Path.STAFF_ID(id)}>
            <Text className="text-pelorous">{name}</Text>
          </ButtonLink>
        </div>
        <div>
          {status && (
            <Pill className="mt-sm" type={PillType.Italic} size="text-xs">
              {status}
            </Pill>
          )}
        </div>
      </>
    );
  }, []);

  const renderTagUids = useCallback((tagUids) => {
    const tagUidsArr = [...tagUids];

    if (tagUidsArr.length === 0) {
      return <Text italic>{lang.noTagYet}</Text>;
    }

    return (
      <div>
        {tagUidsArr.map((tagUid, i) => (
          <Text key={i} tagUid>
            {tagUid}
          </Text>
        ))}
      </div>
    );
  }, []);

  const renderRoles = useCallback((roles) => {
    return (
      <div>
        {roles.map((role, i) => (
          <Text key={i}>{role}</Text>
        ))}
      </div>
    );
  }, []);

  const renderLocations = useCallback((locations) => {
    return (
      <div>
        {locations.map((location, i) => (
          <Text key={i}>{location}</Text>
        ))}
      </div>
    );
  }, []);

  const prepareStaffList = useCallback(() => {
    const mappedStaffs = mapObjects(
      searchStaffResult.data,
      staffListResponse({ locations: getLocationsResult.data })
    );

    return mappedStaffs
      .filter((staff) => {
        return !["Pouch Developer", "PN Support"].includes(staff.name); // Hide Pouchnation accounts
      })
      .map((staff) => {
        const { name, status, tagUids, roles, locations, id } = staff;
        return {
          id,
          staff: renderStaff(name, status, id),
          tagId: renderTagUids(tagUids),
          role: renderRoles(roles),
          location: renderLocations(locations),
        };
      });
  }, [
    getLocationsResult.data,
    searchStaffResult.data,
    renderStaff,
    renderTagUids,
    renderRoles,
    renderLocations,
  ]);

  const staffs = useMemo(() => {
    return prepareStaffList();
  }, [prepareStaffList]);

  const deleteModal = useModal();
  const { selected, setSelected, isAllSelected, setSelectAll, clearSelected, options } =
    useSelectItems({
      items: staffs,
      indeterminate: true,
    });
  const selectID = useSelectID("staff");

  const onOpenDelete = useCallback(() => {
    deleteModal.show({
      ids: selectID.arr,
    });
  }, [deleteModal, selectID.arr]);

  const onSelect = useCallback(
    (id) => {
      setSelected(id);
      selectID.onSel(id);
    },
    [selectID, setSelected]
  );
  const onSelectAll = useCallback(() => {
    setSelectAll();
    const ids = options?.map(({ id }) => id);
    if (!isAllSelected) selectID.onAdd(ids);
    else selectID.onSub(ids);
  }, [isAllSelected, options, selectID, setSelectAll]);

  const applyFilterCb = useCallback(
    async (searchKey) => {
      const { requestState, filterState } = modifyFilters({
        page: 1,
        searchKey,
      });
      await fetchUpdateStore(requestState, filterState);
    },
    [fetchUpdateStore, modifyFilters]
  );

  const clearFilterCb = useCallback(() => {
    const { requestState, filterState } = clearFilter();
    clearSelected();
    selectID.onDel();
    fetchUpdateStore(requestState, filterState);
  }, [clearFilter, clearSelected, fetchUpdateStore, selectID]);

  return (
    <ModuleWrapper>
      <HeaderB
        title={lang.staff}
        description={lang.inviteYourVenueStaff}
        button={{
          path: Path.STAFF_CREATE,
          text: lang.inviteNewStaff,
        }}
        secondaryButton={{
          text: lang.deleteStaff,
          onClick: onOpenDelete,
        }}
        className="mb-md"
      />
      <StaffFilter
        filterState={filterState}
        requestState={requestState}
        modifyFilter={modifyFilter}
        modifyFilters={modifyFilters}
        applyFilter={applyFilterCb}
        clearFilter={clearFilterCb}
      />

      {!staffs.length && !loadingStaff ? (
        <Container className="bg-white text-center min-h-page flex items-center border rounded">
          <div className="m-auto">
            <Image src={SearchingOctopus} className="w-3/5 mx-auto my-lg" />
            <Text size="text-md mb-lg text-gray-500">{lang.createStaffWhoCan}</Text>
          </div>
        </Container>
      ) : (
        <div>
        <text>{searchStaffResult.metadata.total} {lang.searchResults}</text>
        <DataTable
          page={filterState.page}
          pageSize={filterState.pageSize}
          onChangePage={modifyFilters}
          fetchList={fetchUpdateStore}
          total={searchStaffResult.metadata.total}
          loading={loadingStaff || loadingLocations}
          columns={columns}
          data={staffs}
          error={error}
          minWidth="1000px"
          renderEmpty={{
            image: SearchingOctopus,
            title: lang.createStaffWhoCan,
          }}
          selected={selected}
          setSelected={onSelect}
          isAllSelected={isAllSelected}
          setSelectAll={onSelectAll}
        />
        </div>
      )}
      <DeleteModal
        {...deleteModal}
        success={() => {
          deleteModal.onClose();
          clearSelected();
          selectID.onDel();
          fetchStaffs(requestState);
        }}
      />
    </ModuleWrapper>
  );
};

export default StaffList;
