import lang from "translations";

// Function to get columns for onGroundReportRequest
export const onGroundReportRequestColumns = () => {
  let columns = [
    // {
    //   key: "guestProfileId",
    //   text: lang.guestProfileId,
    //   custom: true,
    //   align: "letf",
    //   //sort: true,
    //   width: "w-auto",
    // },
    {
      key: "guestProfileName",
      text: lang.ticketType,
      custom: true,
      align: "left",
      //sort: true,
      width: "w-auto",
    },
    {
      key: "totalSold",
      text: lang.totalSold,
      align: "right",
      nowrap: true,
      //sort: true,
      width: "w-auto",
    },
    {
      key: "totalActivated",
      text: lang.totalActivated,
      align: "right",
      nowrap: true,
      //sort: true,
      width: "w-auto",
    },
    {
      key: "activePercentage",
      text: lang.activePercentage,
      align: "right",
      nowrap: true,
      //sort: true,
      width: "w-auto",
    },
    {
      key: "totalAccessIn",
      text: lang.totalAccessIn,
      align: "right",
      nowrap: true,
      //sort: true,
      width: "w-auto",
    },
    {
      key: "accessedPercentage",
      text: lang.accessedPercentage,
      align: "right",
      nowrap: true,
      //sort: true,
      width: "w-auto",
    },
    {
      key: "notActivated",
      text: lang.notActivated,
      align: "right",
      nowrap: true,
      //sort: true,
      width: "w-auto",
    },
    {
      key: "notActivatedPercentage",
      text: lang.notActivatedPercentage,
      align: "right",
      nowrap: true,
      //sort: true,
      width: "w-auto",
    },
    {
      key: "notAccessed",
      text: lang.notAccessed,
      align: "right",
      nowrap: true,
      //sort: true,
      width: "w-auto",
    },
    {
      key: "notAccessedPercentage",
      text: lang.notAccessedPercentage,
      align: "right",
      nowrap: true,
      //sort: true,
      width: "w-auto",
    },
  ];

  return columns;
};

// Function to get columns for multipleInRequest
export const multipleInRequestColumns = () => {
  let columns = [
    {
      key: "tagUuid",
      text: "Ticket Id",
      custom: true,
      align: "left",
      //sort: true,
    },
    {
      key: "count",
      text: "Count",
      align: "right",
      nowrap: true,
      //sort: true, // Adding sort for this column
    },
    // {
    //   key: "useCount",
    //   text: "Use Count",
    //   align: "right",
    //   nowrap: true,
    //   //sort: true, // Adding sort for this column
    // }
  ];

  return columns;
};

export const bookingColumns = () => {
  let column = [
    {
      key: "bookingId",
      text: "Booking ID",
      //sort: true,
      align: "left",
      width: "w-20",
    },
    {
      key: "customerId",
      text: "Customer ID",
      //sort: true,
      align: "right",
      width: "w-20",
    },
    {
      key: "firstName",
      text: "First Name",
      //sort: true,
      align: "right",
      width: "w-40",
    },
    {
      key: "lastName",
      text: "Last Name",
      //sort: true,
      align: "right",
      width: "w-40",
    },
    {
      key: "email",
      text: "Email",
      //sort: true,
      align: "right",
      width: "w-60",
    },
    {
      key: "phone",
      text: "Phone",
      sort: false,
      align: "right",
      width: "w-40",
    },
    {
      key: "externalTicketId",
      text: "External Ticket ID",
      sort: false,
      align: "right",
      width: "w-40",
    },
    {
      key: "guestProfileName",
      text: lang.ticketType,
      sort: false,
      align: "right",
      width: "w-40",
    },
  ];
  return column;
};

export const transactionRefundColumns = () => {
  let column = [
    {
      key: "guestProfileName",
      text: lang.ticketType,
      //sort: true,
      align: "left",
      nowrap: true,
    },
    {
      key: "maxAmount",
      text: "Max Amount",
      //sort: true,
      align: "right",
      nowrap: true,
    },
    {
      key: "minAmount",
      text: "Min Amount",
      //sort: true,
      align: "right",
      nowrap: true,
    },
    {
      key: "avgAmount",
      text: "Avg Amount",
      //sort: true,
      align: "right",
      nowrap: true,
    },
    {
      key: "totalAmount",
      text: "Total Amount",
      //sort: true,
      align: "right",
      nowrap: true,
    },
  ];
  return column;
};

export const remainingBalanceColumns = () => {
  let column = [     
    {
      key: "minAmount",
      text: "Min Amount",
      //sort: true,
      align: "left",
      nowrap: true,
    },
    {
      key: "maxAmount",
      text: "Max Amount",
      //sort: true,
      align: "right",
      nowrap: true,
    },
    {
      key: "avgAmount",
      text: "Avg Amount",
      //sort: true,
      align: "right",
      nowrap: true,
    },
    {
      key: "totalAmount",
      text: "Total Amount",
      //sort: true,
      align: "right",
      nowrap: true,
    },
  ];
  return column;
};