import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { searchProduct, bulkProductPos, editPos, deleteProduct, getAccountCodes } from "apis";
import {
  DataTable,
  Toast,
  Text,
  Icon,
  Pill,
  ButtonLink,
  Button,
  SimpleDropdown,
} from "components/commons";
import { VenueContext } from "contexts";
import { useApi, useFilter, useModal, useMount, useRouter, useSelectItems } from "hooks";
import { productListFilterRequest, productListResponse } from "mappers";
import { mapObject, pluralize } from "services";
import columns from "./columns";
import ProductFilter from "./product-filter.module";
import { productFilterState } from "./filters";
import lang from "translations";
import { CreateProductModal, PosModal } from "components/modals";
// import DeleteProductModal from "../delete-product-modal/delete-product-modal";
import DeleteSingleProductModal from "../delete-single-product-modal/delete-single-product-modal";
import { HeaderA } from "components/headers";
import { ModuleWrapper } from "components/fragments";
import { PillType, ProductCreationType, ProductStockLevel, ProductType, StyleType } from "enums";
import { Path } from "paths";
import ProductNamePopover from "components/popovers/product-name-popover/product-name-popover";
import { prettifyProductStockLevel } from "services";
import { mixpanel, TrackEvent } from "mixpanel";
import { editOnlineOrder, searchProductExport } from "apis/product.api";
import { useFlags } from "launchdarkly-react-client-sdk";
import { accountingResponse } from "mappers/accounting.mapper";
import AssignRevenueAccountModal from "components/modals/assign-revenue-account-modal/assign-revenue-account-modal";
import { mapAccountOptions } from "services/accounting.service";
import useFilterStore, { filterName } from "hooks/filterStore";
import { isEmpty } from "lodash";
import EditPriceProductPopover from "components/popovers/edit-price-product-popover";
import NoticeEditPopover from "components/popovers/edit-price-product-popover/notice-edit-tooltip";
import EditPriceProductSetPopover from "components/popovers/edit-price-product-set-popover";
import useSelectID from "hooks/useSelectID";

const ProductList = () => {
  const { venue } = useContext(VenueContext);
  const { history } = useRouter();
  const { onlineOrder } = useFlags();
  const posModal = useModal();
  const deleteProductModal = useModal();
  const createProductModal = useModal();
  const assignRevenueAccountModal = useModal();
  const { setState: setFilterState, getState } = useFilterStore();

  const {
    request: searchProductRequest,
    loading: loadingProduct,
    result: searchProductResult = { data: [], metadata: { total: 0 } },
    error,
    mappedData,
  } = useApi({
    api: searchProduct,
    isArray: true,
    mapper: productListResponse,
    _keys: ["categoryId", "categoryName", "locationId", "locationName"],
    handleOwnError: true,
  });

  const { request: accountRequest, mappedData: accountListMappedData } = useApi({
    api: getAccountCodes,
    params: {
      businessId: venue.venueId,
    },
    isArray: true,
    mapper: accountingResponse,
    handleOwnError: true,
  });

  const { request: bulkProductPosRequest, loading: loadingBulkPos } = useApi({
    api: bulkProductPos,
  });

  // const { request: bulkProductRequest, loading: loadingBulk } = useApi({
  //   api: bulkProduct,
  // });

  const { request: editPosRequest, loading: loadingPos } = useApi({
    api: editPos,
  });

  const { request: editOrderRequest, loading: loadingOrder } = useApi({
    api: editOnlineOrder,
  });

  const { request: deleteProductRequest } = useApi({
    api: deleteProduct,
  });

  const { request: searchProductExportRequest, loading: searchProductExportLoading } = useApi({
    api: searchProductExport,
  });

  const { modifyFilter, modifyFilters, clearFilter, filterState, requestState, isFilterDirty } =
    useFilter(
      filterName.product === getState().name && !isEmpty(getState().filter)
        ? getState().filter
        : productFilterState(venue.venueId),
      productFilterState(venue.venueId)
    );

  const [hasAppliedFilter, setHasAppliedFilter] = useState(isFilterDirty);

  const { selected, setSelected, isAllSelected, setSelectAll, clearSelected, replaceSelected } =
    useSelectItems({
      items: mappedData,
      indeterminate: true,
    });
  const selectID = useSelectID("product");

  const onSelect = (id) => {
    setSelected(id);
    selectID.onSel(id);
  };
  const onSelectAll = () => {
    setSelectAll();
    const ids = mappedData?.map(({ id }) => id);
    if (!isAllSelected) selectID.onAdd(ids);
    else selectID.onSub(ids);
  };

  useEffect(() => {
    const obj = {};
    mappedData
      .filter(({ id }) => selectID.arr.includes(id))
      .forEach((item, index) => {
        obj[item["id"]] = {
          ...item,
          index,
          checked: true,
        };
      });
    replaceSelected(obj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mappedData]);

  useMount(() => {
    if (filterName.product !== getState().name)
      setFilterState({
        name: filterName.product,
        filter: {},
      });
    accountRequest();
    fetchProducts(requestState);

    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.productList,
    });
  });

  const fetchProducts = useCallback(
    async (requestState) => {
      await searchProductRequest(mapObject(requestState, productListFilterRequest));
      clearSelected();
    },
    [searchProductRequest, clearSelected]
  );

  const fetchUpdateStore = useCallback(
    async (requestState, filterState) => {
      setFilterState({
        name: filterName.product,
        filter: filterState,
      });
      fetchProducts(requestState);
    },
    [fetchProducts, setFilterState]
  );

  const changePosCb = useCallback(
    async (posStatus, id) => {
      await editPosRequest({ productId: id, posStatus });
      Toast({
        content: lang.changesSaved,
        success: true,
        icon: "check",
      }).open();
      fetchUpdateStore(requestState, filterState);
    },
    [editPosRequest, fetchUpdateStore, filterState, requestState]
  );

  const changeOrderCb = useCallback(
    async (isActiveMobileOrdering, id) => {
      await editOrderRequest({ productId: id, isActiveMobileOrdering });
      Toast({
        content: lang.changesSaved,
        success: true,
        icon: "check",
      }).open();
      fetchUpdateStore(requestState, filterState);
    },
    [editOrderRequest, fetchUpdateStore, filterState, requestState]
  );

  const goToProduct = useCallback((product) => {
    return product.creationType === ProductCreationType.ProductSet ? (
      <ButtonLink path={Path.EDIT_PRODUCT_SET_ID(product.id)}>
        <Text className="text-sm text-black font-semibold text-left"> {product.name} </Text>
      </ButtonLink>
    ) : (
      <ButtonLink path={Path.EDIT_PRODUCT_ID(product.id)}>
        <Text className="text-sm text-black font-semibold text-left"> {product.name} </Text>
      </ButtonLink>
    );
  }, []);

  const products = useMemo(() => {
    return prepareProductList(
      mappedData,
      goToProduct,
      loadingProduct,
      onlineOrder,
      accountListMappedData,
      () => {
        fetchProducts(requestState);
      }
    );
  }, [
    mappedData,
    goToProduct,
    loadingProduct,
    onlineOrder,
    accountListMappedData,
    fetchProducts,
    requestState,
  ]);

  const applyFilterCb = useCallback(
    async (searchKey) => {
      const { requestState, filterState } = modifyFilters({
        page: 1,
        searchKey,
      });
      await fetchUpdateStore(requestState, filterState);      
      setHasAppliedFilter(true);
    },
    [fetchUpdateStore, modifyFilters]
  );

  const clearFilterCb = useCallback(() => {
    const { requestState, filterState } = clearFilter();
    fetchUpdateStore(requestState, filterState);
    setHasAppliedFilter(false);
  }, [clearFilter, fetchUpdateStore]);

  // const productResultContent = useMemo(() => {
  //   if (hasAppliedFilter) {
  //     return `${searchProductResult.metadata.total} ${pluralize(
  //       searchProductResult.metadata.total,
  //       lang.searchResult,
  //       lang.searchResults
  //     )}`;
  //   }
  //   return null;
  // }, [searchProductResult.metadata.total, hasAppliedFilter]);

  const sortCb = useCallback(
    ({ value, key }) => {
      const { requestState } = modifyFilters({ sort: { key, value } });
      fetchUpdateStore(requestState);
    },
    [fetchUpdateStore, modifyFilters]
  );

  const bulkProductPosCb = useCallback(
    async (posStatus) => {
      let len = selectID.arr.length;
      await bulkProductPosRequest({ posStatus, productIds: selectID.arr });
      Toast({
        content:
          len > 1
            ? lang.populate(lang.productUpdated, [len])
            : lang.populate(lang.productsUpdated, [len]),
        success: true,
        icon: "check",
      }).open();
      posModal.close();
      clearSelected();
      selectID.onDel();
      fetchProducts(requestState);
    },
    [selectID, bulkProductPosRequest, posModal, clearSelected, fetchProducts, requestState]
  );

  const showAssignRevenueAccountModal = useCallback(() => {
    assignRevenueAccountModal.show({
      title: "Edit Revenue Account",
      primaryText: lang.banTag,
      initialState: undefined,
      isEdit: false,
      save: () => {
        console.log("test");
      },
    });
  }, [assignRevenueAccountModal]);

  const submitDeleteProduct = useCallback(
    async (params, productName) => {
      try {
        await deleteProductRequest(params);
        Toast({
          content: lang.populate(lang.productDeleted, [productName]),
          success: true,
          icon: "check",
        }).open();
        deleteProductModal.close();
        fetchProducts(requestState);
      } catch (error) {
        deleteProductModal.close();
        fetchProducts(requestState);
        throw error;
      }
    },
    [deleteProductRequest, fetchProducts, requestState, deleteProductModal]
  );

  const actionSelectContent = useMemo(() => {
    let len = selectID.arr.length;
    if (len > 0) {
      return {
        text: `${len} ${pluralize(len, lang.item, lang.items)}`,
        options: [
          {
            text: lang.enableSellingOnPos,
            value: "0",
            onClick: () => {
              posModal.show({
                posStatus: true,
                ok: () => bulkProductPosCb(true),
              });
            },
          },
          {
            text: lang.disableSellingOnPos,
            value: "1",
            onClick: () => {
              posModal.show({
                posStatus: false,
                ok: () => bulkProductPosCb(false),
              });
            },
            divider: true,
          },
          {
            text: "Change Revenue Account",
            value: "2",
            onClick: () => {
              showAssignRevenueAccountModal();
            },
          },
          // {
          //   text: selectedCount > 1 ? lang.deleteSelectedProducts : lang.deleteSelectedProduct,
          //   value: "2",
          //   onClick: () => {
          //     deleteProductModal.show();
          //   },
          // },
        ],
      };
    }
  }, [selectID.arr.length, posModal, bulkProductPosCb, showAssignRevenueAccountModal]);

  const accountOptions = useMemo(() => {
    return mapAccountOptions(accountListMappedData);
  }, [accountListMappedData]);

  const exportCsv = useCallback(() => {
    searchProductExportRequest(mapObject(requestState, productListFilterRequest));
  }, [searchProductExportRequest, requestState]);

  return (
    <ModuleWrapper>
      <HeaderA
        className="mb-md"
        title={lang.products}
        description={lang.createAndManageProducts}
        button={{
          text: lang.createNewProduct,
          onClick: () => {
            createProductModal.show();
          },
        }}
      />
      <ProductFilter
        filterState={filterState}
        requestState={requestState}
        modifyFilter={modifyFilter}
        modifyFilters={modifyFilters}
        applyFilter={applyFilterCb}
        clearFilter={clearFilterCb}
      />
      <ExportButton
        text={lang.exportList}
        total={searchProductResult.metadata.total}
        loading={searchProductExportLoading}
        onClick={() => {
          exportCsv();
        }}
      />
      <div className="md:flex justify-between items-center">
        {actionSelectContent && (
          <div>
            <SimpleDropdown
              className="mb-sm"
              text={actionSelectContent.text}
              options={actionSelectContent.options}
            />
          </div>
        )}
      </div>
      <text>{searchProductResult?.metadata?.total} {lang.searchResults}</text>
      <DataTable
        //resultContent={productResultContent}
        page={filterState.page}
        pageSize={filterState.pageSize}
        onChangePage={modifyFilters}
        fetchList={fetchUpdateStore}
        total={searchProductResult.metadata.total}
        loading={loadingProduct || loadingPos || loadingOrder}
        columns={[
          ...columns,
          {
            key: "action",
            actions: true,
            align: "right",
            actionOptions: [
              {
                text: lang.editProduct,
                onClick: async (column) => {
                  if (column.creationType === "PRODUCT_SET") {
                    history.push(Path.EDIT_PRODUCT_SET_ID(column.id), {
                      page: requestState.page,
                      categories: requestState.categories,
                    });
                  } else {
                    history.push(Path.EDIT_PRODUCT_ID(column.id), {
                      page: requestState.page,
                      categories: requestState.categories,
                    });
                  }
                },
              },
              {
                render: ({ sellableInPos }) => {
                  if (sellableInPos) {
                    return lang.hideInPos;
                  }
                  return lang.activateInPos;
                },
                onClick: ({ id, sellableInPos }) => {
                  changePosCb(!sellableInPos, id);
                },
              },
              onlineOrder && {
                render: ({ isActiveMobileOrdering }) => {
                  if (isActiveMobileOrdering) {
                    return lang.hideOnlineMenu;
                  }
                  return lang.showOnlineMenu;
                },
                onClick: ({ id, isActiveMobileOrdering }) => {
                  changeOrderCb(!isActiveMobileOrdering, id);
                },
              },
              // {
              //   text: lang.manageInventory,
              //   onClick: (column) => {
              //     // TODO: Manage Directory
              //   },
              // },
              {
                text: <Text danger>{lang.deleteAProduct}</Text>,
                onClick: ({ id }) => {
                  deleteProductModal.show({
                    productId: [id],
                  });
                },
              },
            ],
          },
        ]}
        data={products}
        error={error}
        selected={selected}
        setSelected={onSelect}
        isAllSelected={isAllSelected}
        setSelectAll={onSelectAll}
        sort={filterState.sort}
        setSort={sortCb}
        hasAppliedFilter={isFilterDirty && hasAppliedFilter}
      />
      <PosModal {...posModal} toggling={loadingBulkPos} count={selectID.arr.length} />
      {/* <DeleteProductModal
        // deleting={loadingBulk}
        ok={submitDeleteProduct}
        product={denormalizeItems}
        {...deleteProductModal}
      /> */}
      <DeleteSingleProductModal ok={submitDeleteProduct} {...deleteProductModal} />
      <CreateProductModal requestState={requestState} {...createProductModal} />
      <AssignRevenueAccountModal
        {...assignRevenueAccountModal}
        accountingOptions={accountOptions}
        productIds={selectID.arr}
        ok={() => {
          clearSelected();
          selectID.onDel();
          fetchProducts(requestState);
        }}
      />
    </ModuleWrapper>
  );
};

const getRevenueAccountLabel = (revenueAccountCodeId, accountListMappedData) => {
  let label = "-";
  accountListMappedData &&
    accountListMappedData?.forEach((account) => {
      if (account.accountCodeId === revenueAccountCodeId) {
        label = `${account.code ?? "[xxxx]"} - ${account.accountName}`;
      }
    });
  return label;
};

const prepareProductList = (
  mappedData,
  goToProduct,
  loading,
  onlineOrderFlag,
  accountListMappedData,
  onRefresh
) => {
  return mappedData.map((product) => {
    const {
      id,
      productType,
      sellableInPos,
      retailPriceRange,
      supplyPriceRange,
      markUpRange,
      productSkus,
      stockLevel,
      creationType,
      activeLocation,
      isActiveMobileOrdering,
      revenueAccountCodeId,
    } = product;

    return {
      id,
      product: ({ toggleRowItems, active, parent }) => {
        return (
          <div className="flex items-center">
            <ProductNamePopover productId={product.id}>
              <div>{goToProduct(product)}</div>
            </ProductNamePopover>
            {parent && productType === ProductType.Variant && productSkus.length && (
              <Icon
                color="text-gray-lighter"
                name={active ? "arrow-down" : "arrow-right"}
                onClick={toggleRowItems}
              />
            )}
          </div>
        );
      },
      stockLevel: <StockStatus stockLevel={stockLevel}>{stockLevel}</StockStatus>,
      revenueAccountCode: (
        <p className="text-sm">
          {getRevenueAccountLabel(revenueAccountCodeId, accountListMappedData)}
        </p>
      ),
      retailPriceRange:
        productType === ProductType.Variant ? (
          <NoticeEditPopover>
            <div>
              <RetailPriceRange {...{ retailPriceRange, markUpRange }} />
            </div>
          </NoticeEditPopover>
        ) : product.creationType === ProductCreationType.ProductSet ? (
          <EditPriceProductSetPopover
            id={id}
            supplyPriceRange={supplyPriceRange}
            onRefresh={onRefresh}
          >
            <div>
              <RetailPriceRange {...{ retailPriceRange, markUpRange }} />
            </div>
          </EditPriceProductSetPopover>
        ) : (
          <EditPriceProductPopover
            id={id}
            supplyPriceRange={supplyPriceRange}
            onRefresh={onRefresh}
          >
            <div>
              <RetailPriceRange {...{ retailPriceRange, markUpRange }} />
            </div>
          </EditPriceProductPopover>
        ),
      supplyPriceRange: <span className="whitespace-nowrap text-sm">{supplyPriceRange}</span>,
      // markUpRange: <span className="whitespace-nowrap text-sm"></span>,
      sellableInPos,
      creationType,
      opaque: !sellableInPos && !isActiveMobileOrdering,
      _rowItems: productSkus.map((sku) => {
        return {
          product: () => (
            <div className="pl-xl ">
              <Text fontWeight="font-bold">{sku.product}</Text>
            </div>
          ),
          retailPriceRange: <span className="whitespace-nowrap text-sm">{sku.retailPrice}</span>,
          supplyPriceRange: <span className="whitespace-nowrap text-sm">{sku.supplyPrice}</span>,
          // markUpRange: <span className="whitespace-nowrap text-sm">{sku.markUp} </span>,
        };
      }),
      activeLocation: (
        <div className="flex items-end">
          <span className="text-sm text-right">
            {onlineOrderFlag ? activeLocation : sellableInPos ? lang.posOnly : lang.disabled}
          </span>
        </div>
      ),
      isActiveMobileOrdering,
    };
  });
};

const StockStatus = ({ stockLevel }) => {
  if (stockLevel === ProductStockLevel.InStock) {
    return (
      <Pill type={PillType.Greenish} size="text-sm">
        {prettifyProductStockLevel(stockLevel)}
      </Pill>
    );
  } else if (stockLevel === ProductStockLevel.CheckStock) {
    return (
      <Pill type={PillType.Yellow} size="text-sm">
        {prettifyProductStockLevel(stockLevel)}
      </Pill>
    );
  } else if (stockLevel === ProductStockLevel.ReorderStock) {
    return (
      <Pill type={PillType.Reddish} size="text-sm">
        {prettifyProductStockLevel(stockLevel)}
      </Pill>
    );
  } else if (stockLevel === ProductStockLevel.NoStock) {
    return (
      <Pill type={PillType.Opaque} size="text-sm">
        {prettifyProductStockLevel(stockLevel)}
      </Pill>
    );
  } else {
    return <div></div>;
  }
};

const ExportButton = ({ text, total, loading, onClick }) => {
  if (total > 0) {
    return (
      <div className="text-right">
        <Button
          type={StyleType.Secondary}
          className="pt-1 pb-1"
          loading={loading}
          disabled={loading}
          onClick={() => {
            mixpanel.track(TrackEvent.ClickedButton, {
              button: text,
            });
            onClick();
          }}
        >
          {text}
        </Button>
      </div>
    );
  } else {
    return <div></div>;
  }
};

const RetailPriceRange = ({ retailPriceRange, markUpRange }) => (
  <div className="flex flex-col items-end cursor-pointer">
    <span className="whitespace-nowrap text-sm text-right text-pelorous">{retailPriceRange}</span>
    <span className="text-xs text-disabled text-right">
      {markUpRange} {lang.markUp}
    </span>
  </div>
);

export default ProductList;
