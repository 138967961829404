export const onGroundReportRequest = {
  guestProfileId: { key: "guestProfileId" },
  guestProfileName: { key: "guestProfileName" },
  totalSold: { key: "totalSold" },
  totalActivated: { key: "totalActivated" },
  activePercentage: { key: "activePercentage" },
  totalAccessIn: { key: "totalAccessIn" },
  accessedPercentage: { key: "accessedPercentage" },
  notActivated: { key: "notActivated" },
  notActivatedPercentage: { key: "notActivatedPercentage" },
  notAccessed: { key: "notAccessed" },
  notAccessedPercentage: { key: "notAccessedPercentage" },
};

export const multipleInRequest = {
  count: { key: "count" },
  useCount: { key: "useCount" },
  tagUuid: { key: "tagUuid" },
};

export const bookingRequest = {
  bookingId: { key: "bookingId" },
  customerId: { key: "customerId" },
  firstName: { key: "firstName" },
  lastName: { key: "lastName" },
  email: { key: "email" },
  phone: { key: "phone" },
  externalTicketId: { key: "externalTicketId" },
  guestProfileName: { key: "guestProfileName" },
  
};

export const transactionRefundsRequest = {
  data: {
   transform: ({src}) => {
      if (Array.isArray(src.items)) {
        return src.items.map((item) => ({
          guestProfileName: item.guestProfileName,
          totalAmount: item.totalAmount,
          maxAmount: item.maxAmount,
          minAmount: item.minAmount,
          avgAmount: item.avgAmount,
        }));
      }
      return []; // Return an empty array if no items are found
    },
  },
  footerData: {
    transform: ({src}) => {      
      return {
        guestProfileName: "Total",//src.transactionType,
        totalAmount: src.totalAmount,
        maxAmount: src.maxAmount,
        minAmount: src.minAmount,
        avgAmount: src.avgAmount,
      };
    },
  },
};

export const remainingBalanceRequest = {
  minAmount: { key: "minAmount" },
  maxAmount: { key: "maxAmount" },
  avgAmount: { key: "avgAmount" },
  totalAmount: { key: "totalAmount" },
};

// export const topAndSaleRequest = {
//   data: {
//    transform: ({src}) => {
//       if (Array.isArray(src.items)) {
//         return src.items.map((item) => ({
//           guestProfileName: item.guestProfileName,
//           totalAmount: item.totalAmount,
//           maxAmount: item.maxAmount,
//           minAmount: item.minAmount,
//           avgAmount: item.avgAmount,
//         }));
//       }
//       return []; // Return an empty array if no items are found
//     },
//   },
//   footerData: {
//     transform: ({src}) => {      
//       return {
//         guestProfileName:src.transactionType,
//         totalAmount: src.totalAmount,
//         maxAmount: src.maxAmount,
//         minAmount: src.minAmount,
//         avgAmount: src.avgAmount,
//       };
//     },
//   },
// };


export const topAndSaleRequest = {
  data: {
    transform: ({ src }) => {
      debugger
      if (Array.isArray(src.data)) {
        
        return src.data.flatMap((transaction) =>
          transaction.items.map((item) => ({
            transactionType: transaction.transactionType, // Include transaction type for context
            guestProfileName: item.guestProfileName,
            totalAmount: item.totalAmount,
            maxAmount: item.maxAmount,
            minAmount: item.minAmount,
            avgAmount: item.avgAmount,
          }))
        );
      }
      return []; // Return an empty array if no data is found
    },
  },
  footerData: {
    transform: ({ src }) => {
      
      if (Array.isArray(src.data)) {
        return src.data.map((transaction) => ({
          transactionType: transaction.transactionType,
          totalAmount: transaction.totalAmount,
          maxAmount: transaction.maxAmount,
          minAmount: transaction.minAmount,
          avgAmount: transaction.avgAmount,
        }));
      }
      return []; // Return an empty array if no footer data is found
    },
  },
};
