import lang from "translations";
import { Path } from "paths";
import InvoicedSvg from "assets/svg/Invoiced";

const navItems = (access, connectedSiigo = false) => {
  let items = [
    {
      to: Path.SLASH,
      text: lang.dashboard,
      icon: "dashboard2",
      exact: true,
    },
    {
      to: Path.PRODUCT,
      text: lang.selling,
      icon: "shirt",
      items: [
        {
          to: Path.PRODUCT,
          text: lang.products,
        },
        {
          to: Path.CATEGORY,
          text: lang.productCategories,
        },
      ],
    },
    {
      to: Path.INVENTORY,
      text: lang.inventory,
      icon: "inventory",
      items: [
        {
          to: Path.INVENTORY_SUPPLY_ITEM,
          text: lang.supplyItems,
        },
        {
          to: Path.INVENTORY_STOCKTAKE,
          text: lang.stocktake,
        },
      ],
    },
    {
      to: Path.SIIGO_INVOICES,
      text: lang.eInvoice,
      IconComponent: InvoicedSvg,
      hide: !connectedSiigo,
    },
    {
      to: Path.LOCATION,
      text: lang.locations,
      icon: "vendor2",
    },
    {
      to: Path.BOOKING,
      text: lang.bookings,
      icon: "bookings",
    },
    {
      to: Path.MULTI_PROPERTY,
      text: lang.multiProperty,
      icon: "building",
      items: [
        {
          to: Path.MULTI_PROPERTY_BALANCE_MOVEMENT_IN,
          text: lang.balanceMovementsIn,
        },
        {
          to: Path.MULTI_PROPERTY_BALANCE_MOVEMENT_OUT,
          text: lang.balanceMovementsOut,
        },
      ],
    },
    {
      to: Path.STAFF,
      text: lang.staff,
      icon: "staff",
      items: [
        {
          to: Path.STAFF,
          text: lang.staff,
        },
        // {
        //   to: Path.STAFF_PROFILE,
        //   text: lang.staffProfile,
        // },
      ],
    },
    {
      to: Path.GUEST,
      text: lang.guests,
      icon: "guest",
      items: [
        {
          to: Path.GUESTS,
          text: lang.guests,
        },
        {
          to: Path.GUEST_PROFILE,
          text: lang.guestProfile,
        },
      ],
    },
    {
      to: Path.VOUCHER,
      text: lang.vouchers,
      icon: "voucher",
    },
    {
      to: Path.DISCOUNT,
      text: lang.discounts,
      icon: "discount",
    },
    {
      to: Path.TRANSACTION,
      text: lang.transactions,
      icon: "transactions",
      items: [
        {
          to: Path.TRANSACTION,
          text: lang.allTransactions,
        },
        {
          to: Path.REGISTER_REPORT,
          text: lang.registerReports,
        },
        {
          to: Path.END_OF_DAY_REPORT,
          text: lang.endOfDayReports,
        },
      ],
    },
    {
      to: Path.REPORT,
      text: lang.reports,
      icon: "document",
      items: [
        {
          to: Path.REPORT_INVENTORY,
          text: lang.inventoryReport,
        },
        {
          to: Path.REPORT_SALES,
          text: lang.salesReport,
        },
        {
          to: Path.REPORT_CATEGORY,
          text: lang.categoryReport,
        },
        {
          to: Path.REPORT_TIP,
          text: lang.tipReport,
        },
        {
          to: Path.REPORT_ADDITIONAL_CHARGE,
          text: lang.additionalChargeReport,
        },
        {
          to: Path.REPORT_DISCOUNT,
          text: lang.discountReport,
        },
        {
          to: Path.REPORT_VOUCHER,
          text: lang.voucherReport,
        },
        {
          to: Path.REPORT_FREE_CREDITS,
          text: lang.freeCreditsReport,
        },
        {
          to: Path.REPORT_ACCESS_CONTROL,
          text: lang.accessControlReport,
        },
        {
          to: Path.REPORT_ON_GROUND,
          text: lang.onGround,
        },
      ],
    },
    {
      to: Path.DEVICE,
      text: lang.devices,
      icon: "devices",
      items: [
        {
          to: Path.DEVICES,
          text: lang.devices,
        },
        {
          to: Path.DEVICE_SYNC_STATUS,
          text: lang.deviceSyncStatus,
        },
        {
          to: Path.PRINTER,
          text: lang.printers,
        },
      ],
    },
    {
      to: Path.SETTING,
      text: lang.settings,
      icon: "gear",
    },
  ];
  items = items.filter((item) => !item.hide);
  if (!access.length) {
    return items;
  }
  return items
    .filter((item) => {
      return access.includes(item.to);
    })
    .map((item) => {
      if (item.items?.length) {
        return {
          ...item,
          items: item.items.filter((sub) => {
            return access.includes(sub.to);
          }),
        };
      }
      return item;
    });
};

export default navItems;
