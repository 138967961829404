import React, { useCallback, useContext, useMemo } from "react";
import { HeaderA } from "components/headers";
import lang from "translations";
import { Path } from "paths";
import { VenueContext } from "contexts";
import {
  useApi,
  useMount,
  // useRouter,
  useFilter,
  useModal,
} from "hooks";
import { searchLocation } from "apis/location.api";
import { ButtonLink, DataTable } from "components/commons";
import { location } from "mappers";
import { ModuleWrapper } from "components/fragments";
import { mixpanel, TrackEvent } from "mixpanel";
import GenerateCodeModal from "./generate-code-modal";
import { useFlags } from "launchdarkly-react-client-sdk";
import useFilterStore, { filterName } from "hooks/filterStore";
import { isEmpty } from "lodash";

const LocationList = () => {
  const { venue } = useContext(VenueContext);
  const { venueId } = venue;
  const generateCodeModal = useModal();
  const { locationTableManagement } = useFlags();
  const { setState: setFilterState, getState } = useFilterStore();

  const {
    request,
    loading = true,
    error,
    result = { data: [] },
    mappedData,
  } = useApi({
    api: searchLocation,
    isArray: true,
    mapper: location,
  });

  const defaultFilter = {
    venueId,
    page: 1,
    pageSize: 20,
  };
  const { modifyFilters, filterState } = useFilter(
    filterName.location === getState().name && !isEmpty(getState().filter)
      ? getState().filter
      : defaultFilter,
    defaultFilter
  );

  useMount(() => {
    if (filterName.location !== getState().name)
      setFilterState({
        name: filterName.location,
        filter: {},
      });
    request(filterState);
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.locationsList,
    });
  });

  const fetchUpdateStore = useCallback(
    async (requestState) => {
      setFilterState({
        name: filterName.location,
        filter: requestState,
      });
      request(requestState);
    },
    [request, setFilterState]
  );

  const renderData = useMemo(() => {
    return mappedData.map(({ name, id, noOfTables }) => {
      return {
        name: <ButtonLink path={Path.LOCATION_ID(id)}>{name}</ButtonLink>,
        id: id,
        noOfTables: noOfTables ?? "-",
      };
    });
  }, [mappedData]);

  return (
    <ModuleWrapper>
      <HeaderA
        title={lang.locations}
        description={lang.managePlacesYouStock}
        button={{
          path: Path.LOCATION_CREATE,
          text: lang.createNewLocation,
        }}
        className="mb-md"
      />
      <text>{result.metadata?.total} {lang.searchResults}</text>
      <DataTable
        page={filterState.page}
        pageSize={filterState.pageSize}
        onChangePage={modifyFilters}
        fetchList={fetchUpdateStore}
        total={result.metadata?.total}
        renderEmpty={{
          image:
            "https://pouch-inventory.s3.us-east-2.amazonaws.com/landing-image/no-locations.png",
          title: lang.createLocationToStoreYourProducts,
        }}
        loading={loading}
        columns={[
          {
            key: "name",
            text: lang.locations,
            custom: true,
          },
          locationTableManagement && {
            key: "noOfTables",
            text: lang.noOfTables,
          },
          locationTableManagement && {
            key: "action",
            actions: true,
            align: "right",
            actionOptions: [
              {
                text: lang.generateQRCode,
                onClick: (column) => {
                  generateCodeModal.show({
                    id: column.id,
                    noOfTables: column.noOfTables,
                  });
                },
              },
            ],
          },
        ]}
        data={renderData}
        error={error}
      />
      <GenerateCodeModal {...generateCodeModal} />
    </ModuleWrapper>
  );
};

export default LocationList;
