import { dateToTimeAgo } from "./../../mappers/common.mapper";

export const deviceSyncStatusRequest = {
  deviceId: { key: "deviceId" },
  deviceName: { key: "deviceName" },
  version: { key: "version" },
  deviceImei: { key: "deviceImei" },
  totalCheckIn: { key: "totalCheckIn" },
  totalTopUp: { key: "totalTopUp" },
  totalSales: { key: "totalSales" },
  transactionLastSynced: dateToTimeAgo("transactionLastSynced")
};
