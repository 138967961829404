import { searchAdditionalCharge, editAdditionalCharge } from "apis";
import { DataTable, Icon, Switch, Toast } from "components/commons";
import { VenueContext } from "contexts";
import { useApi, useFilter, useMount, useRouter } from "hooks";
import { additionalChargeResponseMapper, productReportListRequest } from "mappers";
import React, { useCallback, useContext, useMemo } from "react";
import { mapObject } from "services";
import { productReportFilterState } from "./filters";
import columns from "./columns";
import lang from "translations";
import { Path } from "paths";
import { HeaderB } from "components/headers";
import { ModuleWrapper } from "components/fragments";
import { mixpanel, TrackEvent } from "mixpanel";
import { ButtonLink } from "components";
import useFilterStore, { filterName } from "hooks/filterStore";
import { isEmpty } from "lodash";

const AdditionalChargeSetting = () => {
  const { siigoConnected } = useContext(VenueContext);
  const { venue } = useContext(VenueContext);
  const { history } = useRouter();
  const { setState: setFilterState, getState } = useFilterStore();

  const {
    request: searchAdditionalChargeRequest,
    loading: locationProductReports,
    mappedData,
    error,
  } = useApi({
    api: searchAdditionalCharge,
    isArray: true,
    mapper: additionalChargeResponseMapper,
  });

  const { modifyFilters, filterState, requestState } = useFilter(
    filterName.additionalCharge === getState().name && !isEmpty(getState().filter)
      ? getState().filter
      : productReportFilterState(venue.venueId),
    productReportFilterState(venue.venueId)
  );

  useMount(() => {
    if (filterName.additionalCharge !== getState().name)
      setFilterState({
        name: filterName.additionalCharge,
        filter: {},
      });
    fetchAdditionalCharges(requestState);

    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.additionalCharges,
    });
  });

  const fetchAdditionalCharges = useCallback(
    (requestState) => {
      searchAdditionalChargeRequest(mapObject({ ...requestState }, productReportListRequest));
    },
    [searchAdditionalChargeRequest]
  );

  const fetchUpdateStore = useCallback(
    async (requestState, filterState) => {
      setFilterState({
        name: filterName.stocktake,
        filter: filterState,
      });
      fetchAdditionalCharges(requestState);
    },
    [fetchAdditionalCharges, setFilterState]
  );

  const editAdditionalChargeRequest = useCallback(
    async (id, showInPos) => {
      try {
        await editAdditionalCharge({ id, showInPos });
        fetchAdditionalCharges(requestState);
        Toast({
          content: lang.changesSaved,
          success: true,
          icon: "check",
        }).open();
      } catch (error) {
        if (error && error.metadata.code === "3143") {
          Toast({
            content: lang.youAreCurrentlyInSiigoEnv,
            error: true,
            icon: "exclamation-fill",
          }).open();
        } else {
          Toast({
            content: lang.somethingWentWrong,
            error: true,
            icon: "exclamation-fill",
          }).open();
        }
      }
    },
    [fetchAdditionalCharges, requestState]
  );

  const renderOnPos = useCallback(
    (id, showInPos) => {
      return (
        <Switch
          value={showInPos}
          onChange={() => {
            editAdditionalChargeRequest(id, !showInPos);
          }}
          disabled={siigoConnected}
        />
      );
    },
    [editAdditionalChargeRequest, siigoConnected]
  );

  const renderName = useCallback(
    (name, id) => {
      if (siigoConnected) {
        return <div className="text-sm">{name}</div>;
      }
      return (
        <ButtonLink
          className="text-left"
          flex={false}
          path={Path.SETTING_ADDITIONAL_CHARGE_EDIT_ID(id)}
        >
          {name}
        </ButtonLink>
      );
    },
    [siigoConnected]
  );

  const renderValue = useCallback((value, type) => {
    const text = type === "PERCENTAGE" ? `${value.toFixed(2)} %` : `$${value.toFixed(2)}`;
    return <div className="text-sm">{text}</div>;
  }, []);

  const prepareAdditionalCharge = useCallback(() => {
    return mappedData.map((additionalCharge) => {
      // console.log("additionalCharge", additionalCharge);
      const { id, name, type, value, locationNames, showInPos } = additionalCharge;

      return {
        // id: id,
        name: renderName(name, id),
        type: type,
        value: renderValue(value, type),
        locationNames: locationNames,
        showInPos: renderOnPos(id, showInPos),
      };
    });
  }, [mappedData, renderName, renderValue, renderOnPos]);

  const additionalCharge = useMemo(() => {
    return prepareAdditionalCharge();
  }, [prepareAdditionalCharge]);

  const sortCb = useCallback(
    ({ value, key }) => {
      const { requestState, filterState } = modifyFilters({ sort: { key, value } });
      fetchUpdateStore(requestState, filterState);
    },
    [fetchUpdateStore, modifyFilters]
  );

  const goToAdd = useCallback(() => {
    history.push(Path.SETTING_ADDITIONAL_CHARGE_ADD);
  }, [history]);

  return (
    <ModuleWrapper
      header={
        <HeaderB
          returnText={lang.settings}
          title={lang.additionalCharges}
          returnPath={Path.SETTING}
          button={{
            iconPrefix: <Icon className="mr-sm" name="plus" paddingless fontSize={12} />,
            text: lang.createNewCharge,
            loading: false,
            disabled: siigoConnected,
            onClick: () => {
              goToAdd();
            },
          }}
        />
      }
    >
      {siigoConnected && (
        <div className="px-6 py-4 mt-3 mb-3 rounded-lg bg-red-100 text-red text-md">
          <div className="flex gap-2">
            <span className="font-bold">{lang.youAreCurrentlyInSiigoEnv}</span>
          </div>
          <div className="whitespace-pre-wrap"></div>
        </div>
      )}
      <DataTable
        pageable={false}
        onChangePage={modifyFilters}
        fetchList={fetchUpdateStore}
        loading={locationProductReports}
        columns={columns}
        data={additionalCharge}
        error={error}
        sort={filterState.sort}
        setSort={sortCb}
      />
    </ModuleWrapper>
  );
};

export default AdditionalChargeSetting;
