import lang from "translations";

// Function to get columns for onGroundReportRequest
export const deviceSyncRequestColumns = () => {
  let columns = [
    // {
    //   key: "deviceId",
    //   text: lang.deviceId,
    //   width: "w-auto",
    //   align: "left",
    // },
    {
      key: "deviceName",
      text: lang.deviceName,
      width: "w-auto",
      align: "left",
    },
    {
      key: "deviceImei",
      text: lang.deviceImei,
      nowrap: true,
      width: "w-auto",
      align: "right",
    },
    {
      key: "totalCheckIn",
      text: lang.totalCheckIn,
      nowrap: true,
      width: "w-auto",
      align: "right",
    },
    {
      key: "totalTopUp",
      text: lang.totalTopUp,
      nowrap: true,
      width: "w-auto",
      align: "right",
    },
    {
      key: "totalSales",
      text: lang.totalSales,
      nowrap: true,
      width: "w-auto",
      align: "right",
    },
    {
      key: "transactionLastSynced",
      text: lang.transactionLastSynced,
      nowrap: true,
      width: "w-auto",
      align: "right",
    },
    {
      key: "version",
      text: lang.version,
      nowrap: true,
      width: "w-auto",
      align: "right",
    }
  ];

  return columns;
};

