const locale = {
  aWholeProductIsFinished:
    "Un producto completo es un producto terminado que puedes vender en tu espacio. Con las variantes, puedes elegir vender diferentes variaciones de este producto, como el sabor, el color y el tamaño. Todos los productos enteros y sus variantes tendrían cada uno su propio artículo de suministro.",
  accessControl: "Control de Acceso",
  accessControlRoleAppDesc: "Este rol no tiene acceso al Panel de Control de Venue en línea.",
  accessControlRoleDashboardDesc:
    "Permitir acceso a la función Comprobar Acceso de la aplicación Venue.",
  accessToken: "Token de acceso",
  accommodation: "Alojamiento",
  account: "Cuenta",
  accountAlreadyExists: "Este e-mail ya está asociado a una cuenta para este espacio",
  accountCantSeemToFind:
    "No hemos podido encontrar tu cuenta. Por favor, contacta con el mánager del espacio para pedir ayuda.",
  accountCode: "Cuenta creada con éxito",
  accountCodeAddedName: 'Cuenta "{0}" añadida.',
  accountCodeAlreadyExist: 'Cuenta "{0}" eliminada.',
  accountEmailAdded: "Mapeo de cuenta",
  accountEmailRemoved: "Configura y mapea tus cuentas al sistema PouchVENUE.",
  accountIsActive: "Configuración de cuenta",
  accountMapping: "Tipo de cuenta",
  accountMappingDescription: "Código de cuenta",
  accountName: 'Código de cuenta "{0}" añadido.',
  accountNotFound: "El código de cuenta ya existe.",
  accountSettings: "Software de Contabilidad",
  accountType: "Nombre de la cuenta",
  accountingSoftware: "Software de contabilidad",
  accounts: "Cuentas",
  accrNo: "Accr#:",
  actions: "Acciones",
  activateInPos: "Activar en POS",
  activateOnPos: "Activar en punto de venta (POS)",
  activateVariant: "Activar variante",
  active: "Activo",
  activeDateRange: "Intervalo de fechas activo",
  actualClosingBalance: "Saldo final real",
  actualQty: "Cant. real",
  actualStockQty: "Cant. real de existencias",
  add: "Dir. (Dirección)",
  addANote: "Añadir una nota...",
  addAValidAttributeAndOptions: "Añadir un atributo válido y opciones.",
  addAWholeProduct: "Añadir un producto completo",
  addAccount: "Añadir cuenta",
  addAnotherAccount: "Añadir otra cuenta",
  addAnotherAttribute: "Añadir otro atributo",
  addAnotherProductOrSupplyItem: "Añadir otro producto o artículo de suministro",
  addAnotherSupplyItem: "Añadir otro artículo de suministro",
  addCustomQuestion: "Añadir pregunta personalizada",
  addNewAccount: "Añadir nueva cuenta",
  addNewAccountCode: "Ejecutar final del día",
  addNewAccountCodeName: 'Añadir nuevo código de cuenta "{0}"',
  addNewPaymentMethod: "Añadir nueva forma de pago",
  addNewSupplyItem: "Añadir nuevo artículo de suministro",
  addNewSupplyItemSelect: 'Añadir nuevo artículo de suministro "{0}"',
  addNewTax: "Añadir nuevo impuesto",
  addNote: "Añadir nota",
  addOption: "Añadir opción",
  addPaymentMethod: "Añadir método de pago",
  addProduct: "Añadir producto",
  addProducts: "Añadir productos o artículos de suministro en este conjunto de productos.",
  addQty: "Agregar cantidad",
  addSupplyItem: "Agregar artículo de suministro",
  addThisProduct: "Añadir este producto como nuevo producto y artículo de suministro.",
  addVariant: "Añadir variante",
  addedStock: "Stock añadido",
  additionalChargeReport: "Reporte de Cargos Adicionales",
  additionalCharges: "Cargos adicionales",
  adjustQuantity: "Ajustar cantidad",
  adjustStockSuccessfully: "Ajustar stock con éxito",
  adjustTheSupplyItemsVariant:
    "Ajustar artículos de suministro y cantidades para esta variante específica.",
  all: "Todos",
  allAccountTypes: "Todos los tipos de cuenta",
  allCategories: "Todas las categorías",
  allDevices: "Todos los descuentos",
  allDevicesUsed: "Todos los dispositivos usados",
  allDiscounts: "Todos los descuentos",
  allGuestProfiles: "Todos los perfiles de huéspedes",
  allLocation: "Todos los lugares",
  allLocations: "Todas las ubicaciones",
  allMeasurements: "Todas las medidas",
  allPaymentMethods: "Todos los métodos de pago",
  allProducts: "Todos los productos",
  allReportsReadyForDownload: "Todos los informes listos para descargar",
  allRightsReserved: "Todos los derechos reservados.",
  allRoles: "Todos los roles",
  allSafeToDeleteLocation: "¡Hecho! No hay productos con esta ubicación.",
  allSelectedAvailablePos: "Todos los productos seleccionados estarán disponibles en POS.",
  allSelectedNotPos: "Los productos seleccionados no se mostrarán en tu POS.",
  allShiftsClosed: "Todos los turnos cerrados",
  allStaff: "Todo el personal",
  allStatus: "Todos los estados",
  allStockLevels: "Todos los niveles de stock",
  allTransactions: "Todas las transacciones",
  allVoucherTypes: "Todos los tipos de voucher",
  allVouchers: "Todos los vouchers",
  allowAccess: "Permitir acceso",
  allowAccessAndConnect: "Permitir acceso y conectar",
  allowBargainDesc: "Al marcar esto, el personal puede cambiar el precio del artículo en POS",
  allowBargainOnThisProduct: "Permitir negociar el valor final del producto",
  allowGuestWBKeeping: "Permitir que los huéspedes conserven sus etiquetas al finalizar.",
  allowOrRestrictGuestWBKeeping:
    "Permitir o restringir que los huéspedes conserven sus etiquetas al salir.",
  allowPostpaidAndLimit: "Permitir créditos de pospago y establecer límite",
  allowPostpaidInGuestProfile:
    "Permitir que este perfil de huésped realice compras sin créditos. Se les cobrará en el registro de salida.",
  allowThisGuestProfileLimit:
    "Permitir que este perfil de huésped haga compras sin créditos. Se les cobrará al salir.",
  allowedPaymentMethod: "Métodos de pago permitidos.",
  almostThere: "¡Ya casi está!",
  alreadyExists: "Ya existe.",
  amount: "Importe",
  anHourAgo: "hace una hora",
  and: "y",
  andWouldBe: "y sería",
  anyMoreQuestions: "¿Alguna otra pregunta? {0}",
  applyFilter: "Aplicar filtro",
  areYouSureYouWantToDeleteLocation:
    "¿Seguro que quieres eliminar {0}? El valor de inventario de esta ubicación actualmente es de {1}.",
  areYouSureYouWantToDeleteProduct:
    "¿Seguro que quieres eliminar {0}? Este artículo de suministro tiene actualmente {1} {2} en stock.",
  areYouSureYouWantToDeleteProduct2:
    "¿Seguro que quieres eliminar {0}? Dejará de estar disponible en tu espacio. Esto no afectará a las transacciones e informes anteriores.",
  areYouSureYouWantToRemoveVariant:
    '¿Seguro que quieres eliminar la variante "{0}"? Su artículo de suministro no se eliminará.',
  arrivalDate: "Fecha de llegada",
  arrivals: "Llegadas",
  askDeleteGuestProfile: "¿Borrar perfil de huésped?",
  askDeleteStaffProfile: "¿Borrar perfil de personal?",
  askGuestInfoCheckIn: "Pedir información del huésped durante el registro.",
  assemble: "Montar",
  assembleAProduct: "Montar un producto",
  asset: "Activo",
  assignAProfile: "Asignar un perfil",
  assignFreeCreditsStaff: "Asignar créditos gratuitos al personal de este grupo",
  assignVoucherStaff: "Asignar vouchers al personal de este grupo",
  assignVoucherToGuest: "Asignar vouchers a los huéspedes de este grupo al registrarse.",
  assignedRooms: "Habitaciones/camas asignadas",
  assignedSpace: "Espacio asignado",
  attribute: "Atributo",
  attributeAlreadyExists: "El nombre del atributo ya existe para este producto",
  automaticallyGenerateSku: "Generar SKU automáticamente",
  availability: "Disponibilidad",
  availabilityOnPos: "Disponibilidad en POS",
  availableAnytime: "Disponible siempre",
  availableEveryday: "Disponible todos los días",
  availableOnPos: "Disponible en el punto de venta (POS)",
  averageAmountPerItem: "Duración media de la estancia",
  averageDurationOfStay: "Valor medio del pedido",
  averageOrderValue: "Cant. media por artículo",
  awaitingInviteResponse: "Esperando respuesta de invitación.",
  back: "Volver",
  backOffice: "Back Office",
  backOfficeRoleAppDesc:
    "Este rol no tiene roles relacionados con la aplicación y no podría iniciar sesión en el dispositivo.",
  backOfficeRoleDashboardDesc:
    "Permite el acceso a la información, las ventas, el inventario, las ubicaciones, el personal, el perfil del invitado, los cupones, los descuentos, el perfil de la impresora y la confguración del lugar del panel del lugar.",
  banATag: "Bloquear una etiqueta",
  banDetails: "Detalles de bloqueo",
  banList: "Lista de bloqueos",
  banTag: "Bloquear etiqueta",
  banTagListSettings: "Configuración de la lista de etiquetas bloqueadas",
  banTags: "Bloquear etiquetas",
  bannedBy: "Bloqueado por",
  bannedTags: "Etiquetas bloqueadas",
  banningThisTagWillOnlyTakeEffect:
    "El bloqueo de esta etiqueta se aplicará la próxima vez que tus dispositivos sean sincronizados y actualizados (normalmente cada 5 minutos cuando están conectados a Internet). Para reflejar estos cambios inmediatamente, por favor, sincroniza y actualiza tus dispositivos una vez que hayas bloqueado esta etiqueta.",
  basicInfo: "Info básica",
  belowParLevel: "Por debajo del nivel par",
  billAmount: "Importe de la factura",
  birthdate: "Fecha de nacimiento",
  birthday: "Cumpleaños",
  bookedBy: "Reservado por",
  bookingAmount: "Cantidad de reservas",
  bookingDescription: "Lleva un registro de todas las reservas de tu negocio.",
  bookingDetails: "Detalles de la reserva",
  bookingId: "ID de reserva",
  bookingList: "Lista de reservas",
  bookingNo: "Nº de reserva",
  bookingStatus: "Estado de reserva",
  bookingSummary: "Resumen de reservas",
  bookings: "Reservas",
  bottle: "botella",
  browseProducts: "Buscar productos",
  browseVouchers: "Examinar vouchers",
  businessAddress: "Dirección de la empresa",
  businessContactNumber: "Número de contacto de la empresa",
  businessDay: "Nombre de la empresa",
  businessDetails: "Detalles de la empresa",
  businessName: "Día laboral",
  byAssemblingAProduct:
    "Al montar un producto, puedes crear un producto con múltiples artículos de suministro, así como desglosar un solo artículo de suministro con variantes.",
  byCreatingPrinterProfiles:
    "Al crear perfiles de impresora, puedes configurar qué productos imprimir en las notas de pedidos para los diferentes puestos de tu espacio, como cocinas, bares, etc.",
  cancel: "Cancelar",
  cancelBanTag: "Cancelar la etiqueta de bloqueo",
  cancelled: "Cancelado",
  cannotBeDeleted: "No se puede eliminar",
  cannotDeletePartOfComposite:
    "{0} no puede ser eliminado ya que forma parte de {1} compuesto {2}.",
  cantFindDesc: "¿No encuentras lo que buscas? Prueba con otra búsqueda o filtro.",
  capacity: "Capacidad",
  card: "Tarjeta",
  cash: "Efectivo",
  categories: "Categorías",
  categoriesDeleted: "{0} categorías eliminadas",
  categorizeAndManage: "Categoriza y gestiona cada transacción con el plan de cuentas.",
  category: "categoría",
  categoryCreated: 'Categoría "{0}" creada.',
  categoryDeleted: 'Categoría "{0}" eliminada.',
  categoryList: "Lista de categorías",
  categoryName: "Nombre de categoría",
  categoryNameAlreadyExist: "El nombre de la categoría ya existe.",
  categoryNameReserved: "El nombre de la categoría está reservado. Por favor, utiliza otro nombre.",
  categoryReport: "Informe de categoría",
  categoryUpdated: 'Categoría "{0}" actualizada.',
  change: "Cambiar",
  changePassword: "Cambiar la contraseña",
  changePhoto: "Cambiar foto",
  changesSaved: "Cambios guardados.",
  changingCurrencyWontAffect:
    "El cambio de moneda no afectará al valor del precio de tus productos, solo a sus símbolos.",
  charge: "Cargo",
  chargeAmount: "Precio a cobrar",
  chargeName: "Nombre del cargo",
  chargeTaxesOnThisProduct: "Cargar los impuestos de este producto",
  chartOfAccounts: "Plan de cuentas",
  chartOfAccountsDescription: "Actualiza y gestiona tu plan de cuentas.",
  checkInDate: "Fecha de registro",
  checkInDetails: "Detalles del registro de entrada",
  checkInQuestions: "Preguntas sobre el registro de entrada",
  checkInSettings: "Configuración del registro de entrada",
  checkIns: "Registros de entrada",
  checkOutDetails: "Detalles del registro de salida",
  checkOutTheGuide: "Consulta la guía para conectar las integraciones",
  checkOuts: "Salidas",
  checkStocks: "Comprobar existencias",
  checkYourEmail: "Verifica tu e-mail",
  checkYourEmailAt: "Verifica tu e-mail en {0} para completar la configuración de tu cuenta.",
  checkYourEmailOrAdmin: "Verifica tu e-mail o contacta con el administrador.",
  checkbox: "Checkbox",
  checkedIn: "Registrado en",
  checkedInBy: "Registrado por",
  checkedInDate: "Fecha de entrada",
  checkedOut: "Registro de salida realizado",
  checkedOutBy: "Registrado por",
  checkedOutDate: "Fecha de salida",
  checkedOutOn: "Registro de salida realizado el",
  checkinId: "ID de registro de entrada",
  checkingYourInternet: "Comprobando la conexión a Internet o datos.",
  checkout: "Salida",
  chooseAPassword: "Utiliza una contraseña fuerte que no utilices en otro sitio.",
  chooseAReason: "Elige un motivo",
  chooseASimpleOrMultipleItem: "Elige uno o varios artículos para crear un producto.",
  chooseAVenue: "Elige un espacio",
  chooseAtleast1Location: "Elige al menos 1 ubicación",
  chooseCategoryColor: "Elige un color para esta categoría",
  chooseColumns: "Elige las columnas que quieres ver en este informe.",
  chooseInformation: "Elige la información que quieres incluir en el desglose de ventas.",
  chooseLocationForThisStaff: "Elige la designación de la ubicación(es) para este personal",
  choosePassword: "Elige la contraseña",
  choosePaymentMethod: "Elige el método de pago",
  chooseProductsCanBeDiscounted: "Elige qué productos del inventario pueden ser descontados.",
  chooseRole: "Elige el rol(es) del personal",
  chooseTheLocationWhereThisProduct:
    "Elige los lugares donde se vende este producto y donde se descuentan los suministros.",
  chooseVouchers: "Elige los vouchers",
  cityStateCountry: "Ciudad, Estado, País, Código Postal",
  clear: "Eliminar",
  clearAllFilters: "Eliminar todos los filtros",
  clearDownloads: "Eliminar descargas",
  clearedTags: "Etiquetas eliminadas",
  clearedUnusedAndExpiredCredits: "Créditos eliminados, no utilizados y caducados",
  clearedUnusedAndExpiredCreditsMessage:
    "Para los créditos que han sido recargados pero eliminados, no reembolsables o están caducados.",
  clearedUnusedAndExpiredCreditsPlaceholder: "[xxxx] - Otros ingresos (caducidad)",
  clearedUnusedExpiredCredit: "Otros ingresos (caducidad)",
  clickHereToRetry: "Haz clic aquí para volver a intentarlo.",
  closePreview: "Cerrar vista previa",
  closeReceiptPreview: "Cerrar vista previa del recibo",
  code: "Código",
  coffeeBeans: "granos de café",
  columns: "Columnas",
  combineYourProducts: "Combina tus productos para venderlos como un conjunto.",
  communityGuidelines: "guías de la comunidad",
  companyDetails: "Datos de la empresa",
  completeStocktake: "Completar inventario",
  completeStocktakeConfirmation: "¿Completar {0}?",
  completeSubscription: "Completar suscripción",
  completeYourSubscription: "Completa tu suscripción para acceder a tu espacio",
  completed: "Completado",
  completedBy: "Completado por",
  completedOn: "Completado en",
  composite: "Compuesto",
  composition: "Composición",
  compositionGuide: "Guía de composición",
  confirmPassword: "Confirmar contraseña",
  confirmStockAdjustment: "Confirmar ajuste de stock",
  confirmTransfer: "Confirmar transferencia",
  confirmed: "Confirmado",
  connectAndInstall: "Conectar e instalar",
  connected: "Conectado",
  contactNumber: "Número de contacto",
  contactUs: "contáctanos",
  contactUs2: "Contacta con nosotros",
  continue: "Continuar",
  continueAs: "Continuar como {0}",
  continueBanningThisTag: "¿Continuar bloqueando esta etiqueta?",
  continuingOnThisPageWillLogout: "Al continuar en esta página, se cerrará la sesión actual.",
  copied: "¡Copiado!",
  copyLink: "Copiar enlace",
  copyrightYear: "Copyright © 2024",
  costOfGoodsSold: "Coste de las mercancías vendidas (COGS)",
  costOfGoodsSoldMessage: "La cuenta por defecto para el coste de un suministro una vez vendido.",
  costOfGoodsSoldPlaceholder: "[xxxx] - COGS/COGS",
  country: "País",
  createALocation: "Crear una ubicación",
  createAccount: "Crear cuenta",
  createAndManageProducts: "Crea y gestiona los productos que quieres vender en tus ubicaciones",
  createAndManageRawMaterials:
    "Crea y gestiona las materias primas y sus existencias para cada ubicación.",
  createAndManagerDiscounts: "Crea y gestiona tus descuentos con productos rebajados",
  createAndManagerVouchers: "Crea y gestiona tus vouchers utilizados para canjear productos",
  createCategoriesToLink: "Crea categorías para vincular y categorizar tus productos",
  createCategory: "Crear categoría",
  createDiscountPage: "Crear página de descuentos",
  createLocation: "Crear ubicación",
  createLocationToStoreYourProducts: "Crea una ubicación para almacenar tus productos.",
  createNewCategory: "Crear nueva categoría",
  createNewCharge: "Crear nuevo cargo",
  createNewDiscount: "Crear nuevo descuento",
  createNewGuestProfile: "Crear nuevo perfil de huésped",
  createNewLocation: "Crear nueva ubicación",
  createNewProduct: "Crear nuevo producto",
  createNewProductSet: "Crear nuevo conjunto de productos",
  createNewProfile: "Crear nuevo perfil",
  createNewStaffProfile: "Crear nuevo perfil de personal",
  createNewStocktake: "Crear nuevo inventario",
  createNewSupplyItem: "Crear nuevo artículo de suministro",
  createNewVoucher: "Crear nuevo voucher",
  createNote: "Crear nota",
  createPrinterProfile: "Crear perfil de impresora",
  createProduct: "Crear producto",
  createProductSet: "Crear conjunto de productos",
  createStaffProfile: "Crear perfil de personal",
  createStaffWhoCan:
    "Crea personal que pueda recargar créditos, registrar huéspeds y vender en tus espacios.",
  createTheProduct: "Crea los productos que quieras vender en tu espacio.",
  createVoucherPage: "Crea una página de voucher",
  createVouchersForGuest: "Crea vouchers para los huéspedes y el personal.",
  createYourOwnIntegration: "Crea tus propias integraciones con PouchCONNECT",
  createYourVenue: "Crea tu espacio",
  createdSupplyItem: "Artículo de suministro creado",
  credit: "Crédito",
  creditAutomaticallyExpires: "Los créditos caducan automáticamente después del registro de salida",
  creditBalance: "Saldo a favor",
  creditLimits: "Límites de crédito",
  creditSettings: "Ajustes de crédito",
  credits: "Créditos",
  creditsDescription:
    "Asigna créditos gratuitos a los huéspeds de este grupo y establece un límite de crédito de pospago.",
  creditsExpiration: "Vencimiento de los créditos",
  creditsExpirationDescription:
    "Configura los créditos para que venzan cuando un huésped haya salido.",
  creditsExpirationOnCheckout: "Vencimiento de los créditos a la salida",
  creditsLiabilityAccount: "Cuenta de pasivo de créditos",
  creditsLiabilityAccountMessage:
    "Cuenta de reserva para todos los créditos que se recargan en una etiqueta antes de la venta.",
  creditsLiabilityAccountPlaceholder: "[xxxx] - Responsabilidad/Créditos",
  creditsNeverExpires: "Los créditos nunca caducarán después de la salida.",
  creditsWouldNeverExpire: "Los créditos nunca caducarán cuando un huésped se haya registrado.",
  currency: "Moneda",
  currencyAndFormat: "Moneda y formato",
  currentGuestProfiles: "Perfil actual de huéspedes",
  currentPassword: "Contraseña actual",
  currentPasswordAndNewPasswordMatched: "La contraseña actual y la nueva coinciden",
  currentQty: "Cantidad actual",
  custom: "Personalizar",
  customDateTime: "Personalizar fecha y hora",
  customFields: "Personalizar campos",
  customQuestion: "Personalizar pregunta",
  customQuestions: "Personalizar preguntas",
  customTax: "Personalizar impuestos",
  customerBehaviourOverTime: "Comportamiento del cliente en el tiempo",
  customizeReceipt: "Personaliza cada sección de tu recibo.",
  customizeSectionReceipt: "Personaliza las secciones de tu recibo.",
  dashboard: "Panel de control",
  dataError: "Error de datos",
  dataIncorrectly: "Datos incorrectos",
  date: "Fecha",
  dateAndTimeOfTransaction: "Fecha y hora de la transacción",
  dateBanned: "Fecha de bloqueo",
  dateCreated: "Fecha de creación",
  dateIssuedWithColonSymbol: "Fecha de emisión:",
  dateModified: "Fecha de modificación",
  dateOfCheckin: "Fecha de entrada",
  dateOfCheckout: "Fecha de salida",
  dateOfRegistration: "Fecha de registro",
  dateRange: "Rango de fechas",
  day: "día",
  days: "días",
  daysAfterCheckout: "días después de la salida",
  debitCredit: "Tarjeta de débito / crédito",
  default: "Por defecto",
  defaultAccountForTaxes: "La cuenta por defecto para los impuestos cargados por cada producto.",
  defaultFields: "Campos por defecto",
  defaultQuestions: "Preguntas por defecto",
  defaultRevenueAccount: "Cuenta de ingresos por defecto",
  defaultRevenueAccountMessage: "La cuenta por defecto para todos los ingresos por ventas.",
  defaultTax: "Impuestos por defecto",
  defectiveTag: "Etiqueta defectuosa",
  defectiveTags: "Etiquetas defectuosas",
  delete: "Eliminar",
  deleteAProduct: "Eliminar producto",
  deleteAProduct2: "¿Eliminar producto?",
  deleteAProductSet: "Eliminar conjunto de productos",
  deleteCategories: "Eliminar {0} categorías",
  deleteCategory: "Eliminar categoría",
  deleteCurrentlyBeingUsed:
    "{0} se está utilizando actualmente en algunos conjuntos de productos. Para continuar, elimina este producto de los siguientes conjuntos de productos",
  deleteDiscount: "Eliminar descuento",
  deleteGuestProfile: "Eliminar perfil de huésped",
  deleteLocation: "Eliminar ubicación",
  deleteLocationNote:
    "Aviso: las transacciones realizadas anteriormente con esta ubicación se conservarán.",
  deleteName: '¿Eliminar "{0}"?',
  deleteNote: "Eliminar aviso",
  deletePaymentMethod: "Eliminar método de pago",
  deletePrinterProfile: "Eliminar perfil de impresora",
  deleteProduct: "¿Eliminar {0}?",
  deleteProductSet: "¿Eliminar el conjunto de productos?",
  deleteProducts: "¿Eliminar {0} productos?",
  deleteProducts2: "Eliminar {0} productos",
  deleteQuestion: "Eliminar pregunta",
  deleteSelectedCategories: "Eliminar categorías seleccionadas",
  deleteSelectedCategory: "Eliminar categoría seleccionada",
  deleteSelectedProduct: "Eliminar producto seleccionado",
  deleteSelectedProducts: "Eliminar productos seleccionados",
  deleteStaff: "Eliminar personal",
  deleteStaffProfile: "Eliminar perfil del personal",
  deleteSupplyItem: "Eliminar artículo de suministro",
  deleteTax: "Eliminar impuestos",
  deleteThis: "Eliminar este {0}",
  deleteThisMultiple: "¿Eliminar {0} {1}?",
  deleteThisQuestion: "¿Eliminar esta pregunta?",
  deleteVoucher: "Eliminar voucher",
  deleted: "Eliminado",
  deletedPaymentMethod: 'Forma de pago "{0}" eliminada.',
  deletedTax: 'Impuesto "{0}" eliminado.',
  deletingLoading: "Eliminando...",
  deletingName: "Eliminando {0}",
  deletingThisLocation:
    "Al eliminar esta ubicación también se eliminarán los saldos de existencias de todos los artículos de suministro. Esta acción no se puede deshacer.",
  denominations: "Denominaciones",
  departure: "Salida",
  departureDate: "Fecha de salida",
  describeThisProduct: "Describe este producto...",
  describeThisProductSet: "Describe este conjunto de productos",
  description: "Descripción",
  destinationLocation: "Ubicación de destino",
  destinationLocationNewQty: "Ubicación de destino nueva cantidad",
  deviceId: "ID del dispositivo",
  deviceImei: "IMEI del dispositivo",
  deviceImeiSerial: "IMEI del dispositivo / Serie",
  deviceInfo: "Información del dispositivo",
  deviceList: "Lista de dispositivo",
  deviceMac: "Dirección MAC del dispositivo",
  deviceMacAddress: "Dirección MAC del dispositivo",
  deviceName: "Nombre del dispositivo",
  deviceNameAlreadyExists: "El nombre del dispositivo ya existe",
  deviceNameUpdated: 'Dispositivo "{0}" actualizado.',
  deviceSerialNumber: "Número de serie del dispositivo",
  devices: "Dispositivos",
  difference: "Diferencia",
  disableSellingOnPos: "Desactivar la venta en POS",
  discard: "Descartar",
  discardChanges: "Descartar cambios",
  discardWristbandKeepingSettings: "Descartar configuración de mantenimiento de la pulsera",
  disclaimerCategoryReport:
    "Claúsula de exención de responsabilidad: Puede haber productos con múltiples categorías asignadas, lo que dará lugar a un doble recuento en cada partida.",
  disconnect: "Desconectar",
  discount: "Descuento",
  discountApplied: "Descuento aplicado",
  discountCanBeApplied: "Este descuento se puede aplicar en tu espacio.",
  discountCreated: 'Descuento "{0}" creado.',
  discountDeleted: 'Descuento "{0}" eliminado.',
  discountName: "Nombre del descuento",
  discountNameAlreadyExist: "Este nombre ya existe.",
  discountRateOrValue: "Tasa o valor de descuento",
  discountReport: "Informe de descuento",
  discountType: "Tipo de descuento",
  discountUpdated: 'Descuento "{0}" actualizado.',
  discountableProducts: "Productos con descuento",
  discounts: "Descuentos",
  discountsPage: "Página de descuentos",
  discrepancy: "Discrepancia",
  displayCategoryInPos: "Mostrar esta categoría en los dispositivos POS.",
  displayedIn: "Visualizado en",
  distribution: "Distribución",
  done: "Hecho",
  dontSellOnPos: "No vender en POS",
  dontWorry: "No te preocupes",
  download: "Descargar",
  downloading: "Descargando...",
  dropdown: "Desplegable",
  duplicateOption: "Opción de duplicado",
  eWallet: "E-wallet",
  easierGuestReg: "Facilitar el registro y la entrada de huéspedes",
  edit: "Editar",
  editAccessToken: "Editar token de acceso",
  editAccount: "Editar cuenta",
  editCategory: "Editar categoría",
  editDevices: "Editar dispositivo",
  editDiscountsPage: "Editar Página de descuento",
  editGuestProfile: "Editar el perfil del huésped",
  editLocation: "Editar ubicación",
  editLocations: "Editar ubicaciones",
  editNewCategory: "Editar nueva categoría",
  editNote: "Editar Nota",
  editPaymentMethod: "Editar método de pago",
  editPaymentMethods: "Editar métodos de pago",
  editPrinter: "Editar perfil de impresora",
  editProduct: "Editar producto",
  editProductSet: "Editar conjunto de productos",
  editQuestions: "Editar preguntas",
  editStaff: "Editar personal",
  editStaffPage: "Editar página del personal",
  editStaffProfilePage: "Editar página de perfil del personal",
  editSupplyItem: "Editar artículo de suministro",
  editTax: "Editar impuestos",
  editVariants: "Editar variantes",
  editVoucherPage: "Editar la página de vouchers",
  editYourAccountPreferences: "Editar preferencias de tu cuenta, dirección de e-mail y contraseña.",
  egAppliesToGuests: "p. ej. Se aplica a los huéspedes de 60 años o más",
  egSeniorDiscount: "p. ej. Descuento para personas mayores",
  egSizeFlavor: "p. ej. Tamaño, sabor",
  eightCharactersMinimum: "8 caracteres como mínimo",
  eitherYouHavePoorConnection:
    "Puede que haya una mala conexión a internet, el acceso haya sido denegado o algo haya ido mal.",
  email: "Email",
  emailAddress: "Dirección de e-mail",
  emailAlreadyInVenue: "Esta dirección de e-mail ya está asociada a una cuenta en este espacio.",
  emailDoesntHaveRecord:
    "El e-mail que has introducido no coincide con nuestro registro, por favor, vuelve a comprobarlo e inténtalo de nuevo.",
  enableSellingOnPos: "Activar venta en POS",
  end: "Finalizar",
  endDay: "Final del día",
  endOfDay: "Saldo final",
  endOfDayChecklist: "Final del día",
  endOfDayReports: "Lista de control final del día",
  enterEachOption: "Introduce cada opción separada por una coma",
  enterEmailToLogin: "Introduce tu e-mail para registrarte o iniciar sesión.",
  enterYourEmailAddress: "Introduce tu dirección de e-mail",
  enterYourPassword: "Introduce tu contraseña",
  enteredPasswordIncorrect: "La contraseña introducida es incorrecta.",
  equity: "Capital",
  errorLoadingColors: "Error al cargar los colores. Por favor, inténtalo de nuevo más tarde.",
  example1: "Ejemplo 1",
  example1AssembleProduct:
    "{0}: Un café con leche necesitaría {1}, {2} y {3}. La deducción se extraería de los granos de café, la leche y el azúcar cuando se compre un café con leche.",
  example2: "Ejemplo 2",
  example2AssembleProduct:
    '{0}: El vino puede venderse como un {1} o un {2}. Selecciona el vino como artículo de suministro, crea las variantes "botella" y "copa" y ajusta la cantidad del artículo por variante, de manera que sea deducido acorde con el mismo artículo de suministro.',
  expStockQty: "Exp. cantidad de stock",
  expectedEndingBalance: "Saldo final esperado",
  expectedQty: "Cant. esperada",
  expense: "Gasto",
  expired: "Caducado",
  export: "Exportar",
  exportCheckinDetails: "Exportar detalles de registro",
  exportList: "Exportar lista",
  exportRegisterReport: "Exportar informe de registro",
  exportTaxReport: "Exportar reporte de impuestos",
  exportTransactions: "Exportar transacciones",
  exportXlsx: "Exportar como XLSX",
  extendThePower: "Impulsa PouchVENUE con los mejores complementos en su clase.",
  extremelyHighDenominations: "Denominaciones extremadamente altas",
  failed: "Fallido",
  failedToLoad: "Fallo en la carga.",
  feedback: "Comentarios",
  feelFreeToContactPouchnation: "Por favor, no dudes en contactar con nosotros en PouchNATION.",
  filters: "Filtros",
  firstName: "Nombre",
  fixedAmount: "Cantidad fija",
  fixedAmountRange: "La cantidad fija debe ser entre 1-9,999,999",
  footerSection: "Sección de pie de página",
  forApproval: "Para aprobación",
  forProperReportingAndTo:
    "Para obtener informes adecuados y mantener su tablero actualizado, ejecute el Fin del día diariamente para evitar la pérdida de datos.",
  forProperReportingWithYourMews:
    "Para obtener informes adecuados con su cuenta de MEWS, asegúrese de que la ventana de historial editable de contabilidad esté configurada en 7 días. Ejecute el Fin del Día diariamente para evitar la pérdida de datos.",
  forceBanned: "Detalles de salida forzada y bloqueo",
  forceCheckOutBy: "Salida forzada por",
  forceCheckout: "Forzar la salida",
  forceCheckoutDetails: "Detalles de la salida forzada",
  forceCheckoutName: "¿Forzar la salida {0}?",
  forcedCheckedOutBy: "Salida forzada por",
  formatting: "Formateando",
  freeCredits: "Créditos gratuitos",
  freeCreditsAreCreditsThatAreFreeOfCharge:
    "Los créditos gratuitos son créditos que no tienen ningún coste. Estos créditos son",
  freeCreditsHoverTooltip:
    "Los créditos gratuitos no son reembolsables y se priorizarán y utilizarán en primer lugar tras los pagos.",
  freeCreditsIssued: "Créditos gratuitos emitidos",
  freeCreditsLiabilityAccount: "Cuenta de pasivo de créditos gratuitos",
  freeCreditsLiabilityAccountMessage:
    "Coste de los créditos gratuitos emitidos a los huéspedes una vez utilizados.",
  freeCreditsLiabilityAccountPlaceholder: "[xxxx] - Responsabilidad/créditos gratuitos",
  freeCreditsRemoved: "Créditos gratuitos eliminados",
  freeTrial: "¡30 días de prueba gratuita!",
  frontOfficeRoleAppDesc:
    "Este rol no tiene roles relacionados con la aplicación y no podría iniciar sesión en el dispositivo.",
  frontOfficeRoleDashboardDesc:
    "Permite el acceso a la información, las reservas, los invitados, las transacciones, los informes, los dispositivos y las etiquetas de prohibición del panel del lugar.",
  gender: "Género",
  general: "General",
  generalSettings: "Configuración general",
  generatingReport: "Generando informe...",
  getNotifications: "Recibir notificaciones",
  getStarted: "Empezar",
  glass: "cristal",
  glossary: "Glosario",
  goToXDashboard: "Ir al panel de control de {0}",
  grossProfit: "Beneficio bruto",
  guest: "Huésped",
  guestCheckedOutAndBanned: "Huéspedes con etiqueta de salida y bloqueados.",
  guestDetails: "Datos del huésped",
  guestInfoNotYetSync: "La información del huésped aún no está sincronizada",
  guestInformationYouCollect: "Información del huésped recogida durante la entrada.",
  guestList: "Lista de huéspedes",
  guestName: "Nombre del huésped",
  guestNameAndOrTagId: "Nombre del huésped y/o ID de la etiqueta",
  guestOrTagStatus: "Estado del huésped o etiqueta",
  guestProfile: "Perfil de huésped",
  guestProfileDeleted: "Perfil de huésped eliminado.",
  guestProfileDescriptionPlaceholder:
    'P. ej., "Huéspedes que se registran en la zona de restaurantes".',
  guestProfileList: "Lista de perfiles de huésped",
  guestProfileName: "Nombre del perfil de huésped",
  guestProfileNameCreate: 'Perfil de huésped "{0}" creado.',
  guestProfileNamePlaceholder: 'P. ej., "Huéspedes de la zona de restaurantes".',
  guestProfiles: "Perfiles de huésped",
  guestQuestions: "Preguntas de los huéspedes",
  guestSince: "Huésped desde {0}",
  guestSpecialRequest: "Peticiones especiales de los huéspedes",
  guestTap: "Tap de huésped",
  guestWithColonSymbol: "Huésped:",
  guests: "Huéspedes",
  guestsCheckedIn: "Huéspedes registrados",
  guestsNewBalance: "Saldo nuevo del huésped",
  guestsPreviousBalance: "Saldo anterior del huésped",
  guestsPreviousBalanceTotalPaidAndNewBalance:
    "Saldo anterior del huésped, total pagado y nuevo saldo",
  guestsStatus: "Estado del huésped",
  headOfBooking: "Huésped principal de la reserva",
  headerSection: "Sección de encabezado",
  hello: "Hola",
  helpCenter: "Centro de ayuda",
  hiHeresWhatsHappening: "Hola {0}, esto es lo que está pasando con {1}",
  hidden: "Oculto",
  hide: "Ocultar",
  hideDetails: "Ocultar detalles",
  hideInPos: "Ocultar en POS",
  hideVariant: "Ocultar variante",
  highDenominations: "Altas Denominaciones",
  holdingAccountForToppedUpItems:
    "Cuenta de reserva de todos los créditos que se recargan en una etiqueta antes de la venta",
  hour: "hora",
  hours: "horas",
  id: "ID",
  ifYouLeaveThisPage:
    "Si abandonas esta página, todos los cambios no guardados se perderán. ¿Seguro que quieres descartar los cambios?",
  ifYoudLikeToJustStopSellingProduct:
    'Si quieres dejar de vender un producto en tu POS, simplemente deshabilítalo desactivando "Vender en POS".',
  imageTypeSize: "JPG GIF o PNG. Tamaño máx. de 1MB",
  impItem: "Importación de artículos",
  impItemMaxSize: "Tamaño máximo 25 MB",
  impItemModalNote1:
    "Para el artículo/producto de suministro que ya existía en el inventario, la importación aumentará la cantidad de ese artículo de suministro en el inventario.",
  impItemModalNote2:
    "Para el artículo de suministro/producto que no existe en el inventario, haga clic en confirmar para generar un nuevo SKU en el inventario.",
  impItemsErr1: "No se puede encontrar la ubicación {0}",
  impItemsErr2: "Medida {0} no encontrada",
  impItemsErr3: "Formato de datos de la hoja {0} {1} incorrecto",
  impItemsErr4: "Hay una diferencia de precio para el artículo de suministro {0} + Medida {1}",
  impItemsErr5: "La hoja {0} no debe exceder las 200 filas",
  impItemsErr6: "Categoría no encontrada",
  impItemsErr7: "Hay una diferencia de precio para el Producto {0} + Medida {1}",
  impItemsPlsRecheck:
    "Vuelva a verificar los datos en la importación de archivos y cárguelos nuevamente.",
  import: "Importar",
  importSupplyItems: "Importar artículos de suministros",
  importSupplyItemsCreateWholeProducts:
    "Importe artículos de suministro y cree productos completos",
  importSupplyItemsErr:
    "Los nombres de las ubicaciones no coinciden con las ubicaciones existentes en el lugar. Por favor vuelva a verificar",
  in: "en",
  inProgress: "En progreso",
  inStock: "En Stock",
  inactive: "Inactivo",
  include: "Incluir",
  indeterminate: "indeterminado",
  information: "Información",
  informationToCollect: "Información a recoger en el registro de huéspedes.",
  integrateDesc: "Integración con potentes sistemas de gestión de propiedades y mucho más.",
  integrationSettingDesc:
    "Conéctate con otras apps de negocios y productividad para aumentar tu negocio.",
  integrationSettings: "Configuración de integración",
  integrations: "Integraciones",
  invalidFileType: "Tipo de archivo no válido. Los tipos de archivo permitidos son JPG o PNG.",
  invalidOptions: "Opción(es) no válida(s).",
  invalidTimeRange: "Rango de tiempo no válido",
  invalidToken: "Token no válido",
  inventory: "Inventario",
  inventoryAccount: "Cuenta de inventario",
  inventoryAccountMessage:
    "Para los artículos de suministro adquiridos e inventario antes de la venta.",
  inventoryAccountPlaceholder: "Cuenta de pérdidas/ganancias del inventario",
  inventoryGainLossAccount:
    "Para las discrepancias en las existencias, ajustes de existencias, etc.",
  inventoryGainLossAccountMessage: "Gestión del inventario",
  inventoryGainLossAccountPlaceholder: "Movimiento del inventario",
  inventoryManagement: "Página de movimiento de inventario",
  inventoryMovement: "Informe del inventario",
  inventoryMovementPage: "Valor del inventario",
  inventoryReport: "Reporte de Inventario",
  inventoryValue: "[xxxx] - Ganancia/pérdida en la venta de inventario",
  invitationHasBeenSentTo: "Se ha enviado una invitación a {0}.",
  inviteNewStaff: "Invitar nuevo personal",
  inviteYourVenueStaff: "Invita al personal del espacio, gestiona su rol y acceso en tu POS",
  isAreserveWord: "es una palabra de reserva.",
  issuanceLimit: "Límite de emisión",
  issue: "Emitir",
  issueFreeCredits: "Emitir créditos gratuitos",
  issuedBy: "Emitido por",
  issuedOn: "Emitido en",
  item: "Artículo",
  itemName: "Nombre del artículo",
  itemTax: "Impuesto sobre el artículo",
  items: "Artículos",
  itemsSold: "Artículos vendidos",
  justNow: "Ahora mismo",
  lastIssuedTo: "Último emitido a",
  lastModifiedBy: "Última modificación realizada por",
  lastModifiedOn: "Última modificación",
  lastName: "Apellidos",
  lastPurchasedProduct: "Último producto comprado",
  lastSync: "Última sinc.",
  lastSynced: "Última sincronización {0}",
  lastVisitedLocation: "Último lugar visitado",
  learnMore: "Más información",
  legalBusinessName: "Nombre legal de la empresa",
  letsGetStarted: "Empezar",
  liability: "Responsabilidad",
  limit: "Límite",
  limitVoucherPerPerson: "Límite de voucher emitido por persona",
  linkAccountingItem: "Vincular partida contable",
  linkWithMewsAccountingItems: "Conectar con artículos contables de MEWS",
  loading: "Cargando...",
  location: "Ubicación",
  locationDesignation: "Ubicación designada",
  locationName: "Nombre de la ubicación",
  locationNameAlreadyExist: "Esta ubicación ya existe. Elige un nombre diferente.",
  locationNameCreated: 'Ubicación "{0}" creada.',
  locationNameDeleted: 'Ubicación "{0}" eliminada.',
  locationNameUpdated: 'Ubicación "{0}" actualizada.',
  locationVoucherRedeemed: "Elija la(s) ubicación(es) donde se canjea este voucher.",
  locations: "Ubicaciones",
  locationsList: "Lista de ubicaciones",
  logIn: "Iniciar sesión",
  loginHasEnded: "Tu sesión ha ha finalizado. Por favor, inicia sesión de nuevo.",
  loginSessionEnded: "Sesión de inicio de sesión finalizada",
  logout: "Cerrar sesión",
  looksLikeAlreadyInSystem: "Parece que ya estás en nuestro sistema",
  looksLikeYouEncounteredSomething:
    "Parece que ha habido un error. Hacemos un seguimiento de estos errores automáticamente. Mientras tanto, actualiza la sesión.",
  looksLikeYouHaveNoLocationYet: "Parece que aún no dispones de ninguna ubicación.",
  lostTag: "Etiqueta perdida",
  lostTags: "Etiquetas perdidas",
  lowDenominations: "Bajas Denominaciones",
  lowOrOutOfStock: "Poco o fuera de stock",
  lowParLevel: "Nivel PAR bajo",
  lowerCaseNoneRefundable: "no reembolsable",
  lowerCaseTopUpCredits: "créditos de recarga",
  mailingList: "Lista de correo",
  manageAccountNotificationStockLevel:
    "Gestionar cuentas para notificar cuando un artículo caiga al nivel de alerta.",
  manageAndAssignQuestions:
    "Gestionar y asignar preguntas en la entrada, créditos gratuitos y vouchers a los diferentes perfiles de huésped.",
  manageBusinessInfo: "Gestionar información de negocio",
  manageEmailNotif: "Gestionar las notificaciones enviadas por e-mail a mi cuenta y al personal.",
  manageHowVenueTax:
    "Gestiona cómo se cobran los impuestos en tu espacio dependiendo de la región. Consulta con un experto en impuestos para entender cuáles son tus obligaciones fiscales.",
  manageImportantDetailsSettings:
    "Gestiona los detalles y reglas importantes de tu espacio, como la configuración de tu cuenta, del espacio, de los pagos y créditos y de la entrada.",
  manageInventory: "Gestionar inventario",
  manageInventoryAndProducts: "Gestionar el inventario y los productos de este conjunto.",
  manageInventoryAndSupply:
    "Gestionar el inventario y los artículos de suministro de este producto.",
  manageLocationTable: "Administrar la disposición de las mesas y los asientos de esta ubicación",
  manageNotifcations: "Gestionar las alertas y notificaciones por e-mail enviadas al personal.",
  managePlacesYouStock:
    "Gestionar los lugares donde se almacena el inventario y se venden los productos.",
  manageReceipts: "Gestionar la apariencia e información de tus recibos.",
  manageSupplyItems: "Gestionar los artículos de suministro",
  manageThePosDevicesVenue: "Gestionar los dispositivos POS registrados y en uso en tu ubicación",
  manageTheStocksPerLocation:
    "Gestionar las existencias de este artículo de suministro por ubicación.",
  manageToppedUpCredits: "Gestionar los créditos recargados, gratuitos, de pospoago y caducados.",
  manageVariants: "Gestionar variantes",
  manageYourAccountsSales:
    "Gestiona tus cuentas en relación con las ventas, coste de los bienes, impuestos sobre los productos o descuentos.",
  manageYourStaffProfileDescription:
    "Gestiona el personal, sus funciones, designaciones y el perfil de personal al que pertenecen.",
  manageYourVenueSetting:
    "Gestiona la configuración de tu espacio, como los impuestos y la moneda.",
  manageYourVenueSubscription:
    "Gestiona la suscripción de tu espacio, la información de facturación y las facturas.",
  manageYourVenueTax: "Añade y gestiona el cobro de impuestos de tu espacio.",
  manager: "Administrador",
  managerAuthorization: "Autorización del manager",
  managerRoleDesc: "Acceso completo tanto al panel de Venue en línea como a la aplicación.",
  mapAndManageAccounts:
    "Mapea y administra tus cuentas predeterminadas para cada proceso de negocio de PouchVENUE.",
  mapAndManageAccountsLink: "Asigna y gestiona tus cuentas en {0} para una contabilidad adecuada",
  mapYourAccounts: "Mapea tus cuentas para activos de inventario, gastos de discrepancia, etc.",
  margin: "Margen",
  markUp: "Margen de beneficios",
  maximumLengthCharacters: "Máximo de {0} caracteres",
  maximumValueAllowed: "El valor máximo permitido es de 20 000 000",
  maximumValueIs: "El valor máximo es ",
  measurement: "Medida",
  measurements: "Medidas",
  mediumDenominations: "Medianas Denominaciones",
  milk: "leche",
  minOfNCharacters: "Mínimo de {0} caracteres",
  minimum: "Mínimo",
  minimumCharactersAllowed: "Mínimo de {0} caracteres permitidos",
  minimumOf: "Se requiere un mínimo de 2 elementos.",
  minimumValueIs: "El valor mínimo es ",
  minute: "minuto",
  minutes: "minutos",
  missedCheckIn: "Registro de entrada no realizado",
  missedCheckOut: "Registro de salida no realizado",
  mixed: "Mixto",
  mobileNumber: "Número de móvil",
  month: "mes",
  months: "meses",
  monthsAfterCheckout: "meses después de la salida",
  mostPurchasedProduct: "Producto más comprado",
  mostVisitedLocation: "Lugar más visitado",
  movement: "Movimiento",
  nOptions: "{0} opciones",
  nTon: "{0} a {1}",
  name: "Nombre",
  nameAdded: "{0} añadido.",
  nameYourProduct: "Nombra tu producto",
  nameYourProductSet: "Nombra el conjunto de productos",
  nameYourSupplyItem: "Nombra el artículo de suministro",
  nationality: "Nacionalidad",
  needHelp: "¿Necesitas ayuda?",
  netWeightN: "Peso neto {0}",
  new: "Nuevo",
  newBalance: "Nuevo balance",
  newGuests: "Nuevos huéspedes",
  newInviteLink: 'Se ha enviado un nuevo enlace de invitación a "{0}"',
  newPassWord: "Nueva contraseña",
  newProduct: "Nuevo producto",
  newProductSet: "Nuevo conjunto de productos",
  newQty: "Nueva cantidad",
  newQuantity: "Nueva cantidad",
  newToPouchNation: "¿Eres nuevo en PouchNATION?",
  no: "No",
  noAccountsAvailable: "Aún no hay ninguna cuenta",
  noAccountsYet: "No hay ninguna cuenta",
  noAvailableCategories: "No hay categorías disponibles",
  noAvailableGuestProfile: "Perfil de huésped no disponible",
  noAvailableLocations: "Ubicaciones no disponibles",
  noAvailableLocationsYet: "Aún no hay ubicaciones disponibles.",
  noAvailablePaymentMethodYet: "Aún no hay un método de pago disponible.",
  noAvailableStaff: "No hay personal disponible",
  noBannedTagsYet: "Aún no hay etiquetas bloqueadas.",
  noBookingsYet: "No hay reservas.",
  noCheckInsInDateRange: "No hay registros para este intervalo de fechas.",
  noData: "Sin datos",
  noDeviceAvailable: "No hay dispositivos disponibles",
  noDevicesFound: "No se han encontrado dispositivos",
  noEndDate: "Sin fecha de finalización",
  noLocation: "Sin ubicación",
  noLocationAvailable: "Sin ubicación disponible",
  noNotesFound: "No se han encontrado notas",
  noOfGuests: "Nº de huéspedes",
  noOfNights: "Nº de noches",
  noOfTables: "# de mesas",
  noProductsAvailable: "No hay productos disponibles",
  noProductsSelected: "Aún no se ha seleccionado ningún producto.",
  noRecordsOf: "Sin registros de",
  noRemainingBalance: "Sin saldo restante",
  noReportsFound: "No se han encontrado informes.",
  noResultInSearch: "No hemos encontrado ningún resultado para tu búsqueda.",
  noResults: "No hay resultados",
  noResultsFound: "No se han encontrado resultados",
  noResultsMatching: "No hay resultados que coincidan",
  noResultsMatchingX: 'No hay resultados que coincidan con "{0}"',
  noReturnCreditsInDateRange: "No hay devolución de créditos para este rango de fechas.",
  noSalesInDateRange: "No hay ventas en este rango de fechas.",
  noSearchResultsForName: "No hay resultados de búsqueda para {0}.",
  noStocks: "Sin stock",
  noTagUid: "No hay etiqueta UID",
  noTagYet: "Sin manilla aún",
  noTagsFound: "No se han encontrado etiquetas.",
  noVoucherAvailable: "No hay vouchers disponibles",
  noVoucherMovement: "Ningún movimiento de vouchers.",
  noVouchersSelectedYet: "No se ha seleccionado ningún voucher.",
  nonRefundable: "No reembolsable",
  notCheckedIn: "No registrado",
  notSellingOnPos: "Sin venta en POS",
  note: "notas",
  notePreviousTransaction:
    "Nota: Tus transacciones anteriores realizadas con estos productos se conservarán.",
  noteTransactionStaff:
    "Nota: Tus transacciones anteriores realizadas con este personal se conservarán.",
  notedByOn: "Anotado por {0} en {1}",
  notes: "Notas",
  notificationSettings: "Configuración de notificaciones",
  notifications: "Notificaciones",
  nthGuestWhoUseThisTag: "{0} huéspeds que han utilizado esta etiqueta.",
  nthStaffWhoUsedThisTag: "{0} personal que ha utilizado esta etiqueta.",
  nthUse: "{0} uso",
  numberOfGuests: "# de huéspedes",
  numberOfNights: "# de noches",
  offline: "Desconectado.",
  ok: "Entendido",
  okay: "Entendido",
  onPos: "En POS",
  onceYouBanATag:
    "Una vez que bloquees una etiqueta ya no podrá ser utilizada para realizar transacciones dentro de tu espacio.",
  onceYouCompleteStocktake:
    "Una vez que completes un inventario, todas las cantidades reales de los artículos de suministro se ajustarán de acuerdo con este. Esta acción no se puede deshacer.",
  open: "Abrir",
  openReceiptPreview: "Abrir vista previa del recibo",
  openWindow: "Abrir ventana de ubicaciones",
  openingBalance: "Saldo de apertura",
  optionNameAlreadyExists: "Este nombre ya existe",
  options: "Opciones",
  orNo: "OR#",
  orderQty: "Cant. de pedido",
  orderYourNFCTags: "Ordenar tus manillas NFC",
  original: "Original",
  other: "Otro",
  others: "Otros",
  overview: "Resumen",
  pairedTagOnCheckin: "Etiqueta emparejada en la entrada",
  pairedTags: "Etiquetas emparejadas",
  parLevel: "Nivel par",
  password: "Contraseña",
  passwordDoesNotMatch: "Las contraseñas introducidas no coinciden",
  passwordIncorrect: "La contraseña que has introducido no es correcta.",
  passwordsDoNotMatch: "Las contraseñas no coinciden",
  paymentAndCreditSettings: "Pago y configuración de créditos",
  paymentMethod: "Método de pago",
  paymentMethodAddedName: 'Método de pago "{0}" añadido.',
  paymentMethodAlreadyExist: "El método de pago ya existe",
  paymentMethodName: "Nombre del método de pago",
  paymentMethods: "Métodos de pago",
  paymentMethodsDescription: "Crea y gestiona los métodos de pago aceptados en tu espacio.",
  paymentMethodsSettings: "Configuración del método de pago",
  paymentMethodsYouAccept: "Métodos de pago aceptados en tu espacio.",
  paymentStatus: "Estado del pago",
  pending: "Pendiente",
  perPage: "{0} por página",
  percentage: "Porcentaje",
  percentageBetween: "El porcentaje debe ser entre 1-100",
  permitNumberWithColonSymbol: "Número de permiso:",
  phone: "Teléfono",
  pleaseCheckYourNetwork: "Por favor, comprueba tu red para actualizar los datos de tu espacio.",
  pleaseChooseLocation: "Por favor elige ubicación",
  pleaseConnectToAnAccounting:
    "Por favor, conéctate a una cuenta de integración para gestionar tu gráfico de cuentas",
  pleaseCreateLocation:
    "Por favor, crea al menos 1 ubicación para asignar las existencias de este artículo de suministro.",
  pleaseCreateYourChartOfAccounts:
    "Por favor, crea tu gráfico de cuentas en la categoría de contabilidad en tu panel de control MEWS.",
  pleaseEnsureLocation:
    "Por favor, asegúrate de que una ubicación no tiene transacciones en curso antes de iniciar el recuento de existencias.",
  pleaseSelectLocation: "Por favor, selecciona una ubicación",
  pleaseSelectStockUnit: "Por favor, selecciona la unidad de stock",
  pointOfSale: "Punto de venta",
  posDevices: "Dispositivos de punto de venta",
  posOnly: "POS",
  posProvider: "Proveedor POS",
  postPaidCreditsIsTheAmount:
    "Los créditos de pospago son la cantidad que un huésped puede utilizar cuando su saldo llega a cero. Cuando un cliente tiene un saldo de créditos de pospago, el importe debe liquidarse y pagarse a través de una recarga o en el momento de la salida.",
  postPaidCreditsUsed: "Créditos de pospago utilizados",
  postpaidCreditLimit: "Límite de crédito de pospago",
  postpaidCredits: "Créditos de pospago",
  postpaidCreditsLiabilityAccount: "Cuenta de pasivo de créditos de pospago",
  postpaidCreditsLiabilityAccountMessage:
    "Créditos utilizados cuando un huésped llega a un saldo de cero. Se pagan a través de una recarga en el momento de la salida.",
  postpaidCreditsLiabilityAccountPlaceholder: "[xxxx] - Activo/Créditos de pospago",
  postpaidCreditsUsed: "Créditos pospago utilizados",
  postpaidCreditsUsedCanOnlyBeReplenished:
    "Los créditos de pospago utilizados sólo pueden reponerse y pagarse a través de una recarga o en el momento de la salida.",
  pouchConnect: "PouchCONNECT",
  pouchLogo: "Logotipo de PouchNATION",
  pouchnation: "PouchNATION",
  powerYourVenue: "Impulsa tu espacio con las integraciones.",
  preloadedVouchers: "Vouchers precargados",
  preparingReport: "Preparación de informes",
  previousBalance: "Saldo anterior",
  price: "Precio",
  pricePerOrderQty: "Precio por cant. de pedido",
  pricing: "Precio",
  pricingAndTaxes: "Precio e impuestos",
  printableItems: "Artículos imprimibles",
  printableItemsSelect: "Elige qué productos se imprimen en este perfil de impresora.",
  printerLocationsDesc:
    "Elige la(s) ubicación(es) donde se puede utilizar este perfil de impresora.",
  printerProfile: "Perfil de la impresora",
  printerProfileCreated: 'Perfil de impresora {0}" creado.',
  printerProfileDeleted: "Perfil de impresora eliminado",
  printerProfileName: "Nombre del perfil de impresora",
  printerProfileNameAlreadyExists: "Este nombre de perfil de impresora ya existe.",
  printerProfileUpdated: 'Perfil de impresora "{0}" actualizado.',
  printerProfiles: "Perfiles de impresora",
  printers: "Impresoras",
  prioritizedAndUsedFirst: "priorizadas y utilizadas en primer lugar tras los pagos.",
  privacy: "Privacidad",
  privacyPolicy: "política de privacidad",
  privacyPolicy2: "Política de privacidad",
  proceed: "Proceder",
  proceedToCheckout: "Proceder a la compra",
  processingTransactions: "Procesando transacciones...",
  product: "Producto",
  productCategories: "Categorías de producto",
  productCategory: "Categoría de producto",
  productCount: "Recuento de productos",
  productDeleted: 'Producto "{0}" eliminado.',
  productList: "Lista de productos",
  productName: "Nombre del producto",
  productRedeemed: "Producto usado",
  productRemovedFromInventory: "{0} producto retirado del inventario.",
  productSet: "Conjunto de productos",
  productSetDeleted: 'Conjunto de productos "{0}" eliminado.',
  productSetName: "Nombre del conjunto de productos",
  productUpdated: "{0} producto actualizado.",
  products: "Productos",
  productsArePartOfComposite:
    "Estos productos forman parte de otros productos compuestos. Para eliminarlos, quítalos primero de los productos compuestos o elimínalos {0} de los productos compuestos.",
  productsAreSupplyItemsAre:
    "{0} son los artículos que vendes en tu espacio. {1} son los artículos que utilizas para construir tus productos. Los artículos de suministro se gestionan en tu {2}.",
  productsCanBeRedeemed: "Elige qué productos de tu inventario se pueden canjear con este voucher.",
  productsDeleted: "{0} productos eliminados.",
  productsDoesNotExist: "Los productos que quieres añadir deben existir en todas las ubicaciones.",
  productsForDeletion:
    "{0} producto(s) con esta ubicación también se pueden eliminar. Esta acción no se puede deshacer.",
  productsRemovedFromInventory: "{0} productos eliminados del inventario.",
  productsToBeDeleted: "Productos a eliminar",
  productsUpdated: "{0} productos actualizados.",
  productsVSupplyItems: "Productos vs. artículos de suministro",
  productsVariants: "Productos / variantes",
  productsWithNoCategory: "Productos sin categoría aún",
  profile: "Perfil",
  profileName: "Nombre del perfil",
  profileNameAlreadyExists:
    "El nombre del perfil ya existe, por favor introduzca un nombre diferente.",
  propertyManagementSystem: "Sistema de gestión de propiedades",
  propertyManagementSystemExample: "Sistemas como MEWS e Cloudbeds",
  purchasingAndInventory: "Compras e inventario",
  qty: "Cnt.",
  quantity: "Cantidad",
  quantitySupplyItems: "La cantidad de artículos de suministro utilizados para este producto.",
  question: "Pregunta",
  questionAddedName: 'Pregunta "{0}" añadida.',
  questionAlreadyExist: "La pregunta ya existe.",
  questionDeleted: 'Pregunta "{0}" eliminada.',
  questionDescription:
    "Información a recoger en el registro de huéspedes. Utiliza estas preguntas asignándolas a tu {0}.",
  questionSettings: "Configuración de preguntas",
  questions: "Preguntas",
  radioButton: "Botón de radio",
  reachedReorderPoint: "Punto de reorden alcanzado",
  readyForDownload: "listos para descargar",
  reallyUnbanTag: "¿Seguro que quieres desbloquear esta etiqueta?",
  reallyUnbanThisTagDescription:
    "El desbloqueo de esta etiqueta se efectuará la próxima vez que se sincronicen y actualicen tus dispositivos (normalmente cada 5 minutos si estás conectado a Internet). Para que estos cambios se reflejen inmediatamente, sincroniza y actualiza tus dispositivos una vez que hayas desbloqueado esta etiqueta.",
  reason: "Razón",
  reasonsForStay: "Motivo de permanencia",
  receiptFormatting: "Formato del recibo",
  receiptNumber: "Número de recibo",
  receiptSettings: "Configuración de recibo",
  receipts: "Recibos",
  recentTransactions: "Transacciones recientes",
  reconnect: "Volver a conectar",
  redeem: "Canjear",
  redeemableProducts: "Productos canjeables",
  redeemed: "Canjeado",
  refreshingThisPage: "Actualizar esta página",
  refundedCredits: "Créditos reembolsados",
  registerGuests: "Registrar huéspedes",
  registerGuestsRoleAppDesc: "Este rol no tiene acceso al panel de control del lugar en línea.",
  registerGuestsRoleDashboardDesc:
    "Permite el acceso a los invitados de Check-in y Check-out de Venue App.",
  registerInfo: "Información de registro",
  registerReports: "Informes de registro",
  registerReportsPage: "Página de información de registro",
  registerStaff: "Registrar personal",
  registeredBusinessInformation: "Información de la empresa registrada",
  remove: "Eliminar",
  removeFreeCredits: "Eliminar créditos gratuitos",
  removeFromList: "Eliminar de la lista",
  removeNameAsYourStaff: "¿Quitar {0} del personal?",
  removeStocks: "¿Quitar existencias?",
  removeStocksConfirm: "Eliminar existencias",
  removeStocksDetails: "¿Seguro que quieres eliminar {0} {1} en existencias de {2} en {3}?",
  removeThisAccount: "¿Eliminar esta cuenta?",
  removeThisSupplyItem:
    "Elimina este artículo de suministro de los productos antes de eliminar este artículo.",
  removeVariant: "Eliminar variante",
  removeVariantFirstFromProduct: "Eliminar antes esta variante del producto",
  removedStock: "Eliminar stock",
  reorderPoint: "Punto de pedido",
  reorderPointReached: "Punto de pedido alcanzado",
  reorderStocks: "Reordenar existencias",
  report: "Informe",
  reportAProblem: "Reportar un problema",
  reports: "Informes",
  reportsFailedToLoad: "Fallo al cargar los informes.",
  reportsInfoPage: "Página de información de informes",
  requireManagerAuthorization:
    "Se requiere la autorización del mánager al reclamar este descuento.",
  requireManagerAuthorizationVoucher:
    "Requerir autorización del gerente al momento de emitir este comprobante.",
  required: "Requerido",
  resendInvite: "¿Reenviar invitación?",
  resetExpiration: "Restablecer caducidad",
  resetTheExpirationPeriod:
    "Restablece el período de caducidad una vez que el huésped complete una transacción sin registrarse.",
  restrictUseOfTags: "Restringe el uso de ciertas etiquetas en tu espacio.",
  retailPrice: "Precio de venta al público",
  retailPriceMustNotBeNegative: "El precio de venta al público no debe ser negativo",
  retryReloadingChart: "Reintentar cargar gráfico",
  returnCredits: "Devolver créditos",
  returnCreditsRoleAppDesc: "Este rol no tiene acceso al Panel de Control de Venue en línea.",
  returnedTags: "Etiquetas devueltas",
  returnedVoucher: "Voucher devueltos",
  returningGuests: "Huéspedes recurrentes",
  revenue: "Ingresos",
  revenueAccount: "Cuenta de ingresos",
  revenueAccountPlaceholder: "[xxxx] - Ingresos/Ventas FOC",
  reviewYourDailyVenueActivity: "Revisa la actividad diaria de tu espacio.",
  revokeInvite: "Revocar invitación",
  revokeThisInvite: "¿Revocar esta invitación?",
  roadMap: "Hoja de ruta",
  role: "Rol",
  roleAndDesignation: "Función y designación",
  roles: "Roles",
  rooms: "Salas",
  runEndOfDay: "Ejecutar final del día",
  sale: "Venta",
  saleDiscounts: "Ingresos/ Descuentos en ventas",
  saleFoc: "Ingresos/ Ventas FOC",
  saleReturn: "Devolución de venta",
  sales: "Ventas",
  salesDiscounts: "Descuentos en ventas",
  salesDiscountsMessage: "El coste de todos los descuentos aplicados.",
  salesDiscountsPlaceholder: "[xxxx] - Descuento de venta",
  salesFOC: "Ventas FOC",
  salesFOCMessage: "El costo de todos los créditos gratuitos aplicados",
  salesFOCPlaceholder: "[xxxx] - Ingresos/ventas FOC",
  salesInformation: "Información de ventas",
  salesOverTime: "Ventas a lo largo del tiempo",
  salesReport: "Informe de ventas",
  save: "Guardar",
  saveAccountSetting: "Guardar configuración de la cuenta",
  saveCategoryForm: "Guardar formulario de categoría",
  saveCreditSetting: "Guardar configuración de crédito",
  saveDeviceForm: "Guardar formulario de dispositivo",
  saveDiscountForm: "Guardar formulario de descuento",
  saveGeneralSettings: "Guardar configuración general",
  saveLocationForm: "Guardar formulario de ubicación",
  saveNewSupplyItemForm: "Guardar formulario de nuevos suministros",
  savePaymentMethod: "Guardar método de pago",
  savePrinterProfileForm: "Guardar formulario de perfil de impresora",
  saveProduct: "Guardar producto",
  saveProductSet: "Guardar conjunto de productos",
  saveQuestion: "Guardar pregunta",
  saveReceiptSettings: "Guardar configuración de recibos",
  saveStaffForm: "Guardar formulario de personal",
  saveStaffProfileForm: "Guardar formulario de perfil del personal",
  saveTaxSetting: "Guardar configuración de impuestos",
  saveVenueForm: "Guardar formulario del espacio",
  saveVoucherForm: "Guardar formulario de vouchers",
  saveWristbandKeepingSettings: "Guardar configuración de mantenimiento de pulseras",
  savedChanges: "Guardar cambios",
  scheduleACall: "Programar una llamada",
  search: "Buscar",
  search2: "Buscar...",
  searchBanTags: "Buscar etiquetas bloqueadas por etiqueta UID",
  searchForProducts: "Buscar productos o artículos de suministro",
  searchForStockId: "Buscar ID de existencias...",
  searchForStocktakeId: "Buscar ID de inventario...",
  searchGuestsByNameOrId: "Buscar huéspedes por nombre o ID de etiqueta",
  searchMovementPlaceholder: "Buscar movimientos por nombre de voucher, dispositivo, huésped",
  searchProductByKeyOrSku: "Buscar productos por palabra clave o SKU",
  searchProductsByKeyword: "Buscar productos por palabra clave",
  searchProductsIn: "Buscar productos en {0}",
  searchReportsByShiftId: "Buscar informes por id de turno",
  searchResult: "buscar resultado",
  searchResults: "buscar resultados",
  searchSupplyItemBy: "Buscar artículo de suministro por nombre o SKU",
  searchTag: "Buscar etiqueta",
  searchTransactionPlaceholder:
    "Buscar transacciones por nombre de huésped, ID de etiqueta, ubicación, ID de entrada",
  searchVouchers: "Buscar vouchers",
  second: "segundo",
  seconds: "segundos",
  selectAType: "Selecciona un tipo",
  selectAccount: "Seleccionar cuenta",
  selectAccountPlaceholder: "Seleccionar cuenta...",
  selectAccountType: "Seleccionar tipo de cuenta",
  selectAndMakeComposition: "Selecciona la marca y la composición de este producto.",
  selectCategoriesOrProducts:
    "Selecciona las categorías o los productos de las ubicaciones elegidas",
  selectCategory: "Seleccionar categoría",
  selectCountry: "Seleccionar país",
  selectDevice: "Seleccionar dispositivo",
  selectGuestProfile: "Seleccionar perfil de huésped",
  selectItemsThisProductMadeOf:
    "Selecciona el/los artículo/s de los que se compone este producto. Establece la cantidad de cada artículo de suministro que se descontará por orden de venta de este producto.",
  selectLocation: "Seleccionar ubicación",
  selectProducts: "Seleccionar productos",
  selectStaff: "Seleccionar personal",
  selectStatus: "Seleccionar estado",
  selectStockLevel: "Seleccionar nivel de stock",
  selectStockUnit: "Seleccionar unidad de stock",
  selectSupplyItem: "Seleccionar artículo de suministro",
  selectTax: "Seleccionar impuesto",
  selectToIncludeCategory: "Selecciona para incluir este producto en una categoría..",
  selectToIncludeProductSetInCategory:
    "Selecciona para incluir este conjunto de producto en una categoría..",
  selectVenue: "Seleccionar espacio",
  selectVouchers: "Seleccionar vouchers",
  sellOnPos: "Vender en POS",
  sellOnPosRoleAppDesc: "Este rol no tiene acceso al panel de control del lugar en línea.",
  sellOnPosRoleDashboardDesc: "Permite el acceso a Ventas y Pedidos en línea de Venue App.",
  selling: "Vendiendo",
  sellingOnPos: "Vendiendo en POS",
  sellingQty: "Cant. de venta",
  serialNumber: "Número de serie",
  serialNumberAbbv: "S/N",
  set: "Configurar",
  setAnAmountToLimitPostPaid:
    "Establecer una cantidad para limitar los créditos pospago permitidos a un huésped.",
  setAsDefaultTax: "Establecer como impuesto por defecto",
  setAsNonRefundable: "Establecer como no reembolsable",
  setCode: "Establecer código",
  setExpirationPeriod: "Establecer periodo de caducidad",
  setUpYourAccount: "Configura tu cuenta",
  sets: "Config.",
  settings: "Configuración",
  share: "Compartir",
  shareThisLink:
    "Comparte este enlace para abrir {0} en cualquier dispositivo. Copie el enlace y compártelo con tus empleados.",
  shift: "Turno",
  shiftEnd: "Fin del turno",
  shiftId: "ID del turno",
  shiftStart: "Inicio de turno",
  shifts: "Turnos",
  shouldBeANumber: "Debe ser un número.",
  show: "Mostrar",
  showHideColumns: "Mostrar / ocultar columnas",
  showLess: "Mostrar menos",
  showMore: "Mostrar más",
  signIn: "Iniciar sesión",
  signInButton: "Botón de inicio de sesión",
  signUp: "Registrarse",
  siigoSendInvoiceError:"Error al enviar la factura. Por favor, haz clic en el número de factura correspondiente para más información.",
  sku: "SKU",
  skuAlreadyExists: "El SKU ya existe.",
  skuStockKeepingUnit: "SKU (Unidad de Mantenimiento de Stock)",
  someArePartOfCompositeNote:
    "{0} {1} de abajo no pueden ser eliminados ya que forman parte de otros productos compuestos. Para borrarlos, elimínalos de los productos compuestos o bórralos {2} con los productos compuestos que los llevan.",
  somethingWentWrong: "Algo ha salido mal.",
  somethingWentWrongPlsTryAgain: "Algo salió mal. Por favor inténtalo de nuevo",
  sourceLocation: "Ubicación de origen",
  sourceLocationOriginalQty: "Ubicación de origen cantidad inicial",
  sourceLocationRemainQty: "Ubicación de origen cantidad restante",
  sourceLocationTransferQty: "Cantidad de transferencia de ubicación de origen",
  spend: "Gasto",
  staff: "Personal",
  staffCanAccess: "El personal puede acceder a la aplicación POS y realizar acciones.",
  staffList: "Lista del personal",
  staffName: "Nombre del personal",
  staffNameAndOrTagId: "Nombre del personal y/o ID de etiqueta",
  staffProfile: "Perfil del personal",
  staffProfileDeleted: "Perfil del personal eliminado",
  staffProfileList: "Lista de perfil del personal",
  staffProfileName: "Nombre del perfil del personal",
  staffProfileNameCreated: 'Perfil de personal {0}" creado.',
  staffProfileNamePlaceholder: "Escribe el nombre de este perfil del personal...",
  staffProfiles: "Perfiles del personal",
  staffRemoved: "Personal eliminado.",
  staffTagWithColonSymbol: "Etiqueta del personal:",
  staffWithColonSymbol: "Personal:",
  standard: "Estándar",
  start: "Iniciar sesión",
  startStocktake: "Iniciar inventario",
  startedOn: "Iniciado en",
  status: "Estado",
  staying: "Permanecer",
  stockAdjSupplyItemsTxtConfirm:
    "¿Está seguro de que desea ajustar el stock de los artículos de suministro en {0}",
  stockAdjustment: "Ajuste del stock",
  stockBelowParLevel: "El stock está por debajo del nivel de distribución",
  stockLevelAlerts: "Alertas de nivel de existencias",
  stockLevelNotificationSettings: "Configuración de notificaciones del nivel de existencias",
  stockLevels: "Nivel de existencias",
  stockManager: "Gerente de existencias",
  stockManagerRoleAppDesc:
    "Este rol no tiene roles relacionados con la aplicación y no podría iniciar sesión en el dispositivo.",
  stockManagerRoleDashboardDesc:
    "Permite el acceso al panel del lugar que está limitado solo al inventario de inventario.",
  stockReachedReorderPoint: "El stock ha alcanzado el punto de pedido",
  stockUnit: "Unidad de stock",
  stockVariance: "Variaciónn de stock",
  stockWeightPerN: "Peso del stock por {0}",
  stocks: "Existencias",
  stocktake: "Inventario",
  stocktakeAlreadyExists: "El inventario ya existe",
  stocktakeSaved: "Inventario guardado",
  stocktakeWithId: "Inventario #ST-{0}",
  streetVillage: "Dirección, Ciudad / Localidad",
  subTotal: "Subtotal",
  subscribeAndRegister: "Suscríbete y regístrate ahora para obtener un",
  subscription: "Suscripción",
  success: "Éxito",
  sugar: "azúcar",
  suggested: "Sugerido",
  summary: "Resumen",
  supplyCost: "Coste del suministro",
  supplyItem: "Artículo de suministro",
  supplyItemAlreadyExists: "El nombre del artículo de suministro ya existe.",
  supplyItemDeleted: "Elemento de suministro eliminado",
  supplyItemNameCreated: 'Elemento de suministro "{0}" creado.',
  supplyItemSummaryPage: "Página de resumen de artículos de suministro",
  supplyItems: "Elementos de suministro",
  supplyTaxes: "Impuestos de suministro",
  supplyUnit: "Unidad de suministro",
  supplyValue: "Costo del suministro",
  syncToMews: "Sincronizar con MEWS",
  tableManagement: "Gestión de mesas",
  tables: "Mesas",
  tagBanned: "Etiqueta prohibida.",
  tagId: "ID de etiqueta",
  tagIds: "IDs de etiqueta",
  tagInfo: "Información de etiqueta",
  tagUID: "Etiqueta UID",
  tagUnbanned: "Etiqueta autorizada.",
  tags: "Etiquetas",
  tagsIssued: "Etiquetas emitidas",
  tapCount: "Recuento de tap",
  tapStatus: "Estado de tap",
  tax: "Impuesto",
  taxAddedName: 'Impuesto "{0}" añadido.',
  taxAlreadyExists: "El impuesto ya existe.",
  taxEditNote:
    "Nota: Los productos vinculados a este impuesto se actualizarán automáticamente. Esto no afectará a las ventas anteriores.",
  taxEg: 'Por ejemplo, "Impuesto sobre el Valor Añadido".',
  taxIncluded: "Impuesto incluido",
  taxInvoiceNumber: "Factura número de impuesto",
  taxName: "Nombre del impuesto",
  taxNumber: "Número de impuesto",
  taxOnPurchasesAccount: "Impuesto sobre cuenta de compras",
  taxOnPurchasesAccountMessage: "IVA y otros impuestos sobre los pedidos de compra.",
  taxOnPurchasesAccountPlaceholder: "[xxxx] - Responsabilidad/impuesto sobre las compras",
  taxOnSalesLiabilityAccount: "Impuesto sobre la cuenta de pasivo de ventas",
  taxOnSalesLiabilityAccountMessage:
    "Cuenta por defecto para los impuestos cargados para cada producto.",
  taxOnSalesLiabilityAccountPlaceholder: "[xxxx] - Obligación/impuesto sobre las ventas",
  taxPercentageAndValue: "Impuesto ({0}%): {1}",
  taxRate: "Tasa de impuestos",
  taxSettings: "Configuración de impuestos",
  taxes: "Impuestos",
  termsAndConditions: "Términos y condiciones",
  termsInAllowingAccess:
    "Al hacer clic en permitir acceso, autorizas a {0} a utilizar, recibir y almacenar tu información. Por favor, revisa los apartados {2} y {3} de {1}. Puedes pausarlo en cualquier momento en la configuración de integraciones de tu cuenta de PouchNATION.",
  termsInSignUp: "Al registrarme, acepto los {0} {1} y {2} de PouchNATION.",
  termsOfService: "condiciones de servicio",
  termsOfUse: "Condiciones de uso",
  textbox: "Cuadro de texto",
  theAmountTheGuestWillPay: "Esta es la cantidad que el huésped pagará por su reserva.",
  theLinkYouAreTryingToAccess: "El enlace al que intentas acceder no es de tu espacio.",
  theLinkYouAreTryingToAccessDoesNotBelongToLocation:
    "El enlace al que intentas acceder no es de tu espacio asignado",
  theShiftAndTransactionDetails:
    "Los detalles del turno y las transacciones se mostrarán aquí una vez que el turno se cierre en el registro.",
  thereIsSomeError: "¡Vaya! Ha ocurrido un error. Por favor,vuelve a intentarlo más tarde.",
  thereNthOtherProducts:
    "{0} {1} otro(s) producto(s) con esta ubicación y otras ubicaciones permanecerán en tu inventario.",
  theseProductsCannotBeDeleted: "Estos productos no pueden ser eliminados.",
  thisAppRequests: "Esta app solicita la siguiente información:",
  thisAppWouldRead: "Esta aplicación requiere la siguiente información:",
  thisEmailIsAlreadyAdded: "Este e-mail ya se ha añadido.",
  thisEmailIsInvalid: "Este e-mail no es válido.",
  thisFieldIsRequired: "Este campo es obligatorio.",
  thisLocationHasTables: "Esta ubicación tiene mesas y arreglos de asientos",
  thisMonth: "Este mes",
  thisProductNameAlreadyExists: "Este nombre de producto ya existe",
  thisProductsHasMultipleVariant:
    "Este producto tiene múltiples opciones como tamaño, color, sabor, etc.",
  thisQuestionIsLinkedToNProfiles: "Esta pregunta está vinculada a {0} perfil(es) de huésped.",
  thisServesAsAnOfficialReceipt: "- ESTO SIRVE COMO RECIBO OFICIAL -",
  thisSkuExists: "Este SKU ya existe",
  thisStaffHasNpLongerAccess:
    "El personal ya no tendrá acceso a {0}. Esta acción no se puede deshacer.",
  thisTagIdIsAlreadyBanned: "Este ID de etiqueta ya está bloqueado.",
  thisVenueFreeTrial: "Este espacio tendrá una prueba gratuita durante los primeros 30 días.",
  thisWeek: "Esta semana",
  time: "Hora",
  timeEnded: "Hora de finalización",
  timeRange: "Intervalo de tiempo",
  timeStarted: "Hora de inicio",
  to: "Para",
  toAddMoreCreditsToATag: "Para añadir más créditos a una etiqueta, los huéspedes pueden",
  toBeCheckedIn: "Para realizar un registro de entrada",
  toBeCheckedOut: "Para realizar un registro de salida",
  toContinueCreatingDiscount: "Para seguir creando este descuento crea primero una ubicación.",
  toContinueCreatingProducts: "Para seguir creando este producto, crea primero una ubicación.",
  toContinueCreatingStaff: "Para seguir creando este personal, crea primero una ubicación.",
  toContinueCreatingStocktake:
    "Para continuar creando este inventario, crea primero una ubicación.",
  toDeleteItem: "para eliminar este artículo.",
  today: "Hoy",
  together: "Juntos",
  topCategoriesBySales: "Categorías principales por ventas",
  topLocations: "Ubicaciones principales",
  topProducts: "Productos principales",
  topSpendingGuests: "Huéspedes que más gastan",
  topupAndReturnCredits: "Créditos de recarga y devolución",
  topupCredits: "Créditos de recarga",
  topupCreditsRoleAppDesc: "Este rol no tiene acceso al panel de control del lugar en línea.",
  topupCreditsRoleDashboardDesc: "Permite el acceso a la recarga de la aplicación Venue.",
  total: "Total",
  totalAmount: "Importe total",
  totalBookings: "Total de reservas",
  totalCreditsKept: "Total de créditos retenidos (después de finalizar la compra)",
  totalCreditsReceived: "Total de créditos recibidos",
  totalFreeCreditsIssued: "Total de créditos gratuitos emitidos",
  totalFreeCreditsRemoved: "Total de créditos gratuitos eliminados",
  totalInStock: "Total en stock",
  totalPaid: "Total pagado",
  totalProductSales: "Total de productos vendidos",
  totalRefundedCredits: "Total de créditos reembolsados",
  totalRemoved: "Total eliminado",
  totalReturnCredits: "Total de créditos devueltos",
  totalReturnedCredits: "Total de créditos devueltos",
  totalSales: "Ventas totales",
  totalSpent: "Total gastado",
  totalSpentToDate: "Total gastado hasta la fecha",
  totalStocksPerLocation: "Total de existencias por ubicación",
  totalTagsUsed: "Total de etiquetas emitidas",
  totalTax: "Total de impuestos",
  totalTopUps: "Total de recargas",
  totalTopupCredits: "Total de créditos de recarga",
  totalTransactions: "Total de transacciones",
  totalUnspentCredits: "Total de créditos no gastados",
  totalUnspentCreditsDesc:
    "Suma de todos los créditos no gastados de los huespedes que están vinculados a su {0} y están programados para salir entre las fechas de su filtro anterior.",
  totalUse: "Uso total",
  totalVisits: "Total de visitas {0}",
  totals: "Totales",
  trackAndManage: "Controla y gestiona los recuentos manuales de inventario.",
  trackEveryGuestCheckedIn:
    "Haz un seguimiento de cada huésped registrado en tu espacio y obtén acceso a sus productos y ubicaciones favoritos",
  trackWhichItemsLow:
    "Haz un seguimiento de los artículos bajos en existencias y que necesitan ser respuestos",
  trackedInventory: "Seguimiento de inventario",
  transaction: "Transacción",
  transactionId: "ID de transacción",
  transactionInfoPage: "Página de información de la transacción",
  transactionStatus: "Estado de la transacción",
  transactionType: "Tipo de transacción",
  transactions: "Transacciones",
  transactionsFailedToProcess: "Las transacciones no se han podido procesar.",
  transactionsPage: "Página de transacciones",
  transfer: "Transferir",
  transferSupplyItems: "Transferir artículos de suministros",
  transferSupplyItemsSuccessfully: "Transferir artículos de suministros con éxito",
  transferSupplyItemsTxtConfirm:
    "¿Está seguro de que desea transferir artículos de suministros de {0} a {1}",
  try: "Intento:",
  turnOffPos: "¿Desactivar la venta de {0} {1}?",
  turnOnPos: "¿Activar la venta de {0} {1}?",
  txnId: "Txn Id: #",
  type: "Escribe",
  typeAQuestion: "Escribe una pregunta",
  typeAReason: "Escribe un motivo...",
  typeAnOption: "Escribe una opción",
  typeDisconnect: "Escribe DISCONNECT en mayúsculas para confirmar",
  typeHere: "Escribe aquí...",
  typeToSearch: "Escribe para buscar un artículo",
  typeToSetAccountCode: "Escribe para establecer/seleccionar un código de cuenta.",
  unableToDelete: "No se puede borrar",
  unbaleToUploadMoreThanMb: "No se puede cargar imágenes con más de 1mb.",
  unban: "Desbloquear",
  unbanTag: "Desbloquear la etiqueta",
  unbanningWillMakeTagAccessible: "El desbloqueo hará que esta etiqueta sea accesible en tu local.",
  uncategorized: "Sin categoría",
  undefinedError: "Error no definido",
  undefinedErrorPlsTryAgain: "Error no definido. Actualice la página e inténtelo de nuevo.",
  undo: "DESHACER",
  unitSold: "Unidad vendida",
  unitsSold: "Unidades vendidas",
  unknown: "Desconocido",
  updatedOn: "actualizado el {0}",
  uploadPhoto: "Subir foto",
  uponRegistration:
    "en el momento del registro o con cualquier personal/quiosco de recarga dentro del espacio.",
  usage: "Uso",
  usageDatePeriod: "Uso de fechas",
  useVenueAppAndLogin:
    "Utiliza la app del espacio y accede con tu etiqueta NFC activada o con tu nombre de usuario o contraseña actual. ¿Aún no tienes la aplicación? Pregunta a mánager del espacio o a {0}.",
  used: "Usado",
  usesLastIssuedTo: "Usados, emitidos por última vez a",
  validUntilWithColonSymbol: "Válido hasta;",
  value: "Valor",
  valueBetween: "El valor debe estar entre",
  valueRedeemed: "Valor canjeado",
  variant: "Variante",
  variantAlreadyExist: "La variante ya existe",
  variantIsCurrentlyBeingUsed:
    "Esta variante está siendo usada en el siguiente producto en este momento",
  variants: "Variantes",
  vatReg: "Reg. de IVA",
  venueCurrency: "Moneda del espacio",
  venueDashboard: "Panel de control del espacio",
  venueDetails: "Detalles del espacio",
  venueInformation: "Información Venue",
  venueLocation: "Ubicación del espacio",
  venueName: "Nombre del espacio",
  venueNameAlreadyExist: "El nombre del espacio ya existe, por favor elige otro nombre",
  venueSettings: "Configuración del espacio",
  veryHighDenominations: "Denominaciones mui altas",
  view: "Ver",
  viewAllTransactions: "Ver todas las transacciones",
  viewAndExportDiscountReport:
    "Ver y exportar la lista de descuentos y el importe total deducido por cada descuento",
  viewAndExportEachClosedRegister:
    "Ver y exportar cada informe de registro cerrado realizado por el personal",
  viewAndExportSalesReportByCategories: "Ver y exportar el informe de ventas por categorías",
  viewAndExportVoucherReport:
    "Ver y exportar todos los vouchers aplicados en productos canjeados y el importe total de venta al por menor y suministro canjeado por cada voucher",
  viewBillingStatement: "Ver extracto de facturación",
  viewBookingInfo:
    "Ver la información de reservas de diferentes plataformas como MEWS, PouchNATION Ticketing, ¡y más!",
  viewCategoryDetails: "Ver detalles de categoría",
  viewDetails: "Ver detalles",
  viewDevice: "Ver dispositivo",
  viewDiscountDetails: "Ver detalles de descuentos",
  viewEveryTransactions:
    "Ver todas las transacciones realizadas en el espacio dentro de un periodo determinado de tiempo",
  viewIntegrations: "Ver integraciones",
  viewNOfNTransactions: "Ver {0} / {1} Transacciones",
  viewNTransactions: "Ver {0} Transacciones",
  viewPlansAndPricing: "Ver planes y precios",
  viewPreview: "Ver vista previa",
  viewTaxSettings: "Ver configuración de impuestos",
  viewTheSalesSummaryOfYourVenue:
    "Ver resumen de ventas del espacio y las principales ubicaciones, productos y más.",
  viewVoucherDetails: "Ver detalles de voucher",
  void: "Anular",
  voidAuthorization: "Anular autorización",
  voidConfirmation: "¿Seguro que quieres anular {0}? Esta acción no puede deshacerse.",
  voidId: "¿Anular {0}?",
  voidInfo: "Info de anulación",
  voidReason: "Razón de anulación",
  voidStocktake: "Anular inventario",
  voided: "Anulado",
  voidedBy: "Anulado por",
  voidedItems: "Artículos anulados",
  voidedOn: "Anulado el",
  voidedValue: "Valor anulado",
  voucher: "Voucher",
  voucherApplied: "Voucher aplicado",
  voucherAvailable: "Este voucher está disponible para ser emitido tu espacio.",
  voucherCreated: "{0} añadido.",
  voucherDeleted: "Vale {0} eliminado.",
  voucherMovementPage: "Página de movimiento de vouchers",
  voucherName: "Nombre del voucher",
  voucherNameAlreadyExist: "Este nombre ya existe.",
  voucherReport: "Informe de voucher",
  voucherRetailValue: "Valor de venta de los vouchers",
  voucherSupplyValue: "Valor de los vouchers de suministro",
  voucherType: "Tipo de voucher",
  voucherUpdated: "Vouchers actualizados",
  vouchers: "Vouchers",
  vouchersCreationLimitInfo:
    "Solo puedes crear un máximo de 8 vouchers por espacio. Puedes eliminar los vouchers caducados o inactivos para añadir más vouchers.",
  vouchersIssued: "Vouchers emitidos",
  vouchersListPage: "Página de lista de vouchers",
  vouchersMaximumNumberReached: "Número máximo de vouchers alcanzado.",
  vouchersRedeemed: "Vouchers canjeados",
  vouchersRemoved: "Vouchers eliminados",
  vouchersUsage: "Uso de vouchers",
  weDidNotFoundReports: "No hemos encontrado ningún informe para tus filtros.",
  weeksAfterCheckout: "semanas después de la salida",
  weightInGrams: "Peso en gramos",
  welcomeBack: "¡Hola de nuevo!",
  whatAreFreePostpaidCredits: "¿Qué son los Créditos Grats y los Créditos Postpago?",
  whenAnItemAlertLevel: "Cuando un artículo cae a su nivel de alerta.",
  whole: "Entero",
  wholeProduct: "Productos enteros",
  withPendingPayments: "Con pagos pendientes",
  withRemainingBalance: "Con saldo restante",
  wristbandKeeping: "Mantenimiento de la pulsera",
  wristbandTag: "Etiqueta de pulsera",
  wristbandTagKeeping: "Etiqueta de mantenimiento de la pulsera",
  wristbandTagSettings: "Ajustes de etiqueta de la pulsera",
  xConnectedFailed:
    "Parece que no podemos reconocer este token de acceso. Por favor, consulta las siguientes instrucciones para recuperar tu token de acceso.",
  xConnectedSuccessfully: "{0} Conectado con éxito",
  year: "año",
  years: "años",
  yes: "Sí",
  yesterday: "Ayer",
  youAreAboutToDelete: 'Estás a punto de eliminar el {0} "{1}". Esto no se puede deshacer.',
  youAreAboutToDeleteAQuestionThatIsLinked:
    "Estás a punto de eliminar una pregunta vinculada a un perfil de huésped. Esto no se puede deshacer.",
  youAreAboutToDeleteGuestProfile:
    "Estás a punto de eliminar este perfil de huésped. Esto no se puede deshacer.",
  youAreAboutToDeleteMultiple: "Estás a punto de eliminar el {0} {1}. Esto no se puede deshacer.",
  youAreAboutToDeletePaymentMethod: 'Estás a punto de eliminar el método de pago "{0}".',
  youAreAboutToDeleteQuestion:
    "Estás a punto de eliminar esta pregunta. Esto no se puede deshacer.",
  youAreAboutToDeleteStaffName:
    "Estás a punto de eliminar a {0} del personal. Este personal ya no tendrá acceso a {1}. Esta acción no se puede deshacer.",
  youAreAboutToDeleteStaffProfile:
    "Estás a punto de eliminar este perfil de personal. Esta acción no se puede deshacer.",
  youAreAboutToDeleteTax: 'Estás a punto de eliminar el impuesto "{0}".',
  youAreAboutToDisconnect:
    "Estás a punto de desconectar y eliminar permanentemente toda la información de esta app.",
  youAreAboutToRemoveFromMailingList:
    "Estás a punto de eliminar a {0} de esta lista de correo. Esta cuenta no podrá recibir alertas del nivel de existencias.",
  youAreAboutToRemoveMultipleProduct:
    "Estás a punto de eliminar {0} productos y sus variantes del inventario y de todas tus ubicaciones. Esto no se puede deshacer.",
  youAreAboutToRemoveProduct:
    "Estás a punto de eliminar este producto del inventario y de todas las ubicaciones. Esto no se puede deshacer.",
  youAreAboutToRemoveVariantProduct:
    "Estás a punto de eliminar este producto y sus {0} variantes del inventario y de todas las ubicaciones. Esto no se puede deshacer.",
  youAreAssigned: "Tienes asignados los siguientes roles:",
  youAreLoggedInAs: "Has iniciado la sesión como {0}",
  youCanChangeThisLater: "Puedes cambiarlo más tarde.",
  youCanNowLogin: "Ya puedes iniciar sesión con tu e-mail y contraseña.",
  youDontHavePermission: "No tienes permiso para acceder a este enlace",
  youHaveUnsavedChanges: "Tienes cambios sin guardar en esta página.",
  youllFindYourBookingsHere: "Encontrarás tus reservas aquí.",
  yourGeneralSettings: "Tus ajustes generales, como el nombre del espacio y la moneda.",
  yourMailingIsEmpty: "Tu lista de correo está vacía.",
  yourVenues: "Tus espacios",
  youveReachedTheEndOfTheList: "Has llegado al final de la lista.",
  zeroInventory: "Inventario cero",
  mapToSiigo: "Corresponde en Siigo",
  pouchnationTax: "Impuesto en PouchNATION",
  siigoDescSalesTax: "Vincular los impuestos en PouchNATION a los impuestos correspondientes en Siigo. Si no selecciona un impuesto en Siigo, no se crearán las facturas que incluyan el impuesto.",
  salesTax: "Impuesto de ventas",
  paymentMethodInSiigo: "Forma de pago en Siigo",
  pouchnationPaymentMethod: "PouchNATION Forma de pago",
  siigoDescPaymentMethod: "Introduzca el nombre del producto o servicio al que se asociarán sus propinas. Este producto será creado (o actualizado, si ya existe) en Siigo y asociado con las propinas en el venue para propósitos de facturación.",
  siigoDescInventoryGroup: "En Siigo, un grupo de inventario se utiliza para organizar y categorizar productos similares. Si un producto en el venue no tiene asignado un grupo de inventario específico, se colocará automáticamente en el Grupo de Inventario por Defecto.",
  defaultInventoryGroup: "Grupo de inventario por defecto",
  productSettings: "Configuración del producto",
  siigoDescSeller:"Este vendedor será el vendedor para la factura creada en Siigo",
  seller: "Vendedor",
  siigoDescBill:"Es necesario que el tipo de descuento esté configurado como 'Porcentaje' y que esté activo en Siigo.",
  invoiceType: "Tipo de factura",
  syncFromSiigo: "Sincronizar desde Siigo",
  syncFromSiigoDesc:"Si añade o actualiza su información en Siigo (tipo de factura, vendedor, grupo de inventario, método de pago o impuestos), tendrá que sincronizarla de nuevo para que se corresponda con su equivalente en PouchNATION.",
  pleaseFetchDataFromSiigoByClickingButtonBelow:"Por favor, obtenga los datos de Siigo haciendo clic en el botón de abajo",
  connectStatus:"Estado de conexión",
  siigoAccount: "Cuenta Siigo",
  youCanReviewQuestionInEachGuestProfile:"Puede revisar las preguntas en el perfil de cada huésped aquí",
  warningSiigoIntegrationDesc: "Para cumplir con la normativa de la Dian, es obligatorio recopilar la siguiente información de los huéspedes: Nombre, Correo electrónico, Tipo de identificación e ID.",
  pleaseReadThisInstructionCarefully:"Lea atentamente estas instrucciones",
  warning:"Advertencia",
  siigoIntegrationDetail:"Detalle de la integración de Siigo",
  viewInfo:"Ver información",
  paymentTerms:"Condiciones de pago",
  totalItems:"Total artículos",
  unitPrice:"Precio Unitario",
  from: "De",
  invoiceDate:"Fecha de factura",
  invoiceNumber:"Número de factura",
  sendToDian:"Enviar a DIAN",
  sendToSiigo:"Enviar a Siigo",
  cufe:"CUFE",
  siigoInvoiceNumber:"Número de factura Siigo:",
  viewInvoice:"Ver factura",
  siigoInvNo:"Número de factura Siigo",
  issueDate:"Fecha de emisión",
  resyncEInvoice:"Volver a sincronizar la factura electrónica",
  acceptedByDian:"Aceptado por Dian",
  dianError:"Dian Error",
  invoicedInSiigo:"Facturado en Siigo",
  siigoError:"Siigo Error",
  unbill:"No facturado",
  state:"Estado",
  searchEInvoicePlaceholder:"Buscar factura por número de factura Siigo, nombre del huésped o número CUFE",
  viewListInvoiceTitle:"Ver la lista de facturas de sus huéspedes con el registro de salida realizado",
  eInvoice:"Facturación electrónica",
  invoice: "Factura",
  siigoCardDesc: "Siigo es un avanzado software de contabilidad en línea con herramientas de facturación conformes con la Dian, esencial para optimizar la eficiencia e impulsar el crecimiento empresarial.",
  connect: "Conectar"
};

export default Object.freeze(locale);
